import { BrowserRouter as Router, Route, Switch, Link, Redirect } from 'react-router-dom';
import Profile from '../../Pages/Private/Profile';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Business from '../../Pages/Private/Business';
import { isMobile, isTablet } from 'react-device-detect';
import Appointments from '../../Pages/Private/Appointments';
import Notifications from '../../Pages/Private/Notifications';
import Logout from '../../Pages/Private/Logout';
import Kesfet from '../../Pages/Private/Kesfet';


function Panel() {

    const location = useLocation();
    const [selected, setSelected] = useState(0)
    const currentUrl = location.pathname + location.search;
    const authenticated = useSelector(state => state.login)
    const [isMenuOpen, setIsMenuOpen] = useState(false)

    useEffect(() => {

        if (currentUrl == "/profil")
            setSelected(0)
        else if (currentUrl == "/randevularim")
            setSelected(1)
        else if (currentUrl == "/isletmeler")
            setSelected(2)
        else if (currentUrl == "/bildirimler")
            setSelected(3)
        else if (currentUrl == "/logout")
            setSelected(4)
        else if (currentUrl == "/kesfet")
            setSelected(5)


    }, [currentUrl])


    return (
        <div style={{ position: "relative" }}>
            {
                isMenuOpen && isMobile &&
                <div onClick={() => setIsMenuOpen(!isMenuOpen)} style={{ position: "absolute", zIndex: "10100", width: "100%", height: "100%", backgroundColor: "black", opacity: "0.4" }}></div>
            }
            {
                isMenuOpen && isMobile &&

                <div style={{ borderRight: "1px solid #EAEAEA", height: "100vh", width: "300px", position: "absolute", top: "0px", left: "0px", backgroundColor: "white", zIndex: "1000000" }}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                        <button onClick={() => setIsMenuOpen(!isMenuOpen)} style={{ margin: "10px", border: "none", backgroundColor: "white" }}><i style={{ fontSize: "24px" }} class="ri-close-line"></i></button>
                    </div>
                    <Link style={{ textDecoration: "none", color: "black"}} to="/">RANDEVU DEFTERİM</Link>
                    <div style={{ display: "flex", justifyContent: "center", height: "100%", width: "100%", marginTop: "50px" }}>
                        <div style={{ width: "100%" }}>
                            <div style={{ paddingLeft: "10px", display: "flex", alignItems: "center", justifyContent: "start", marginTop: "10px", height: "50px", backgroundColor: selected == 5 ? "#16CDB7" : "white" }}>
                                <i style={{ color: selected == 5 ? "white" : "black" }} class="ri-calendar-line"></i>
                                <Link style={{ textDecoration: "none", color: selected == 5 ? "white" : "black" }} to="/kesfet">Explore</Link>
                            </div>
                            <div style={{ paddingLeft: "10px", display: "flex", alignItems: "center", justifyContent: "start", marginTop: "10px", height: "50px", backgroundColor: selected == 1 ? "#16CDB7" : "white" }}>
                                <i style={{ color: selected == 1 ? "white" : "black" }} class="ri-calendar-line"></i>
                                <Link style={{ textDecoration: "none", color: selected == 1 ? "white" : "black" }} to="/randevularim">Appointments</Link>
                            </div>
                            <div style={{ paddingLeft: "10px", display: "flex", alignItems: "center", justifyContent: "start", marginTop: "10px", height: "50px", backgroundColor: selected == 2 ? "#16CDB7" : "white" }}>
                                <i style={{ color: selected == 2 ? "white" : "black" }} class="ri-money-dollar-circle-line"></i>
                                <Link style={{ textDecoration: "none", color: selected == 2 ? "white" : "black" }} to="/isletmeler">Businesses</Link>
                            </div>
                            <div style={{ paddingLeft: "10px", display: "flex", alignItems: "center", justifyContent: "start", marginTop: "10px", height: "50px", backgroundColor: selected == 3 ? "#16CDB7" : "white" }}>
                                <i style={{ color: selected == 3 ? "white" : "black" }} class="ri-notification-line"></i>
                                <Link style={{ textDecoration: "none", color: selected == 3 ? "white" : "black" }} to="/bildirimler">Notifications</Link>
                            </div>
                            <div style={{ paddingLeft: "10px", display: "flex", alignItems: "center", justifyContent: "start", backgroundColor: selected == 0 ? "#16CDB7" : "white", height: "50px" }}>
                                <i style={{ color: selected == 0 ? "white" : "black" }} class="ri-user-line"></i>
                                <Link style={{ textDecoration: "none", color: selected == 0 ? "white" : "black" }} to="/profil">Profile</Link>
                            </div>
                            <div style={{ paddingLeft: "10px", display: "flex", alignItems: "center", justifyContent: "start", backgroundColor: selected == 4 ? "#16CDB7" : "white", height: "50px" }}>
                                <i style={{ color: selected == 4 ? "white" : "black" }} class="ri-logout-circle-line"></i>
                                <Link style={{ textDecoration: "none", color: selected == 4 ? "white" : "black" }} to="/logout">Logout</Link>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div style={{ backgroundColor: "#F5F5F5", height: "100vh", overflow: "hidden" }}>
                <div className='container-fluid'>
                    <div className='row justify-content-center'>
                        <div className='col-12 col-sm-12 col-md-10 col-lg-12 col-xl-12' style={{ padding: "0px" }}>
                            <div style={{ backgroundColor: "white", border: "1px solid red", height: "10vh", border: "1px solid #EAEAEA", marginBottom: "30px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                {
                                    !(isMobile || isTablet) &&
                                    <Link style={{ textDecoration: "none", color: "black", fontSize: "28px", marginLeft: "20px" }} to="/">RANDEVU DEFTERİM</Link>
                                }
                                {
                                    (isMobile || isTablet) &&
                                    <div>
                                        <button onClick={() => setIsMenuOpen(!isMenuOpen)} style={{ margin: "10px", border: "none", backgroundColor: "white" }}><i style={{ fontSize: "36px" }} class="ri-menu-line"></i></button>
                                    </div>
                                }
                                {
                                    !(isMobile || isTablet) &&
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <Link style={{ display: "flex", alignItems: "center", textDecoration: "none", color: "black", margin: "10px", fontSize: "14px", color: selected == 5 ? "white" : "#5a5961", backgroundColor: selected == 5 ? "#16CDB7" : "transparent", padding: "10px", borderRadius: "10px" }} to="/kesfet"><i style={{ marginRight: "5px" }} class="ri-search-line"></i>Explore</Link>
                                        <Link style={{ display: "flex", alignItems: "center", textDecoration: "none", color: "black", margin: "10px", fontSize: "14px", color: selected == 1 ? "white" : "#5a5961", backgroundColor: selected == 1 ? "#16CDB7" : "transparent", padding: "10px", borderRadius: "10px" }} to="/randevularim"><i style={{ marginRight: "5px" }} class="ri-calendar-2-line"></i>Appointments</Link>
                                        <Link style={{ display: "flex", alignItems: "center", textDecoration: "none", color: "black", margin: "10px", fontSize: "14px", color: selected == 2 ? "white" : "#5a5961", backgroundColor: selected == 2 ? "#16CDB7" : "transparent", padding: "10px", borderRadius: "10px" }} to="/isletmeler"><i style={{ marginRight: "5px" }} class="ri-store-2-line"></i>Businesses</Link>
                                        <Link style={{ display: "flex", alignItems: "center", textDecoration: "none", color: "black", margin: "10px", fontSize: "14px", color: selected == 3 ? "white" : "#5a5961", backgroundColor: selected == 3 ? "#16CDB7" : "transparent", padding: "10px", borderRadius: "10px" }} to="/bildirimler"><i style={{ marginRight: "5px" }} class="ri-notification-line"></i>Notifications</Link>
                                        <Link style={{ display: "flex", alignItems: "center", textDecoration: "none", color: "black", margin: "10px", fontSize: "14px", color: selected == 0 ? "white" : "#5a5961", backgroundColor: selected == 0 ? "#16CDB7" : "transparent", padding: "10px", borderRadius: "10px" }} to="/profil"><i style={{ marginRight: "5px" }} class="ri-user-line"></i>Profile</Link>
                                        <Link style={{ display: "flex", alignItems: "center", textDecoration: "none", color: "black", margin: "10px", fontSize: "14px", color: selected == 4 ? "white" : "#5a5961", backgroundColor: selected == 4 ? "#16CDB7" : "transparent", padding: "10px", borderRadius: "10px" }} to="/logout"><i style={{ marginRight: "5px" }} class="ri-logout-circle-line"></i>Logout</Link>

                                    </div>
                                }

                                <div>
                                    <Link to="/profil" style={{ color: "black", fontWeight: "bold", textDecoration: "none" }}>
                                        <div style={{ marginRight: "20px" }}>
                                            {authenticated.user.name}

                                            <img src={authenticated.user.image != null ? authenticated.user.image : "default.png"} style={{ borderRadius: "50px", height: "50px", width: "50px", marginLeft: "20px", border: "1px solid #d9d9d9" }} />
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ height: "85vh", overflow: "auto", paddingBottom: "100px" }}>  
                    <Switch>
                        <Route path="/profil" component={Profile} />
                        <Route path="/isletmeler" component={Business} />
                        <Route path="/randevularim" component={Appointments} />
                        <Route path="/bildirimler" component={Notifications} />
                        <Route path="/logout" component={Logout} />
                        <Route path="/kesfet" component={Kesfet} />
                    </Switch>
                </div>
            </div>
        </div>
    );
}

export default Panel;


import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import MainMenu from '../../Components/MainMenu';
import { useState } from 'react';
import axiosInstance from '../../Axios';
import { login } from '../../Redux/Actions/login';
import md5 from "md5";
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet-async';

function Sifremiunuttum() {

    const dispatch = useDispatch();

    const [phone, setPhone] = useState("")
    const [phoneMessage, setPhoneMessage] = useState("")
    const [loading, setLoading] = useState(false)

    const [loginMessage, setLoginMessage] = useState("")


    const forgetPasswordHandle = async () => {



        setLoading(true);

        try {
            const response = await axiosInstance.post('/login/forgetpassword',
                {
                    phone,
                });

            toast.success("The password reset link has been sent by e-mail.")

            setTimeout(() => {
                setLoading(false)
                window.location.href = "/"
            }, 2000)


        } catch (error) {

            setTimeout(() => {
                setLoginMessage(error.response != undefined ? error.response.data.error : "We are experiencing a connection problem. Try again later.")
                setLoading(false);
            }, 2000)

        }



    }

    return (
        <div className="App">
            <Helmet>
                <title>Şifremi Unuttum</title>
                <meta name='description' content='Randevu defterime hemen şifreni değiştir. Randevu takip sistemini kullanmaya hemen başla. Ücretsiz olan bu uygulamada sınırsız personel ekleyebilirsiniz. Müşterilerinize e-postalar yollayabilirsiniz.' />
            </Helmet>
            <MainMenu type={1} />
            <div>
                <div className='container-fluid' >
                    <div className='row justify-content-center'>
                        <div className='col-12 col-sm-12 col-md-10 col-lg-6 col-xl-3' style={{ padding: "0px", zIndex: "10" }}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "50px" }}>
                                <div style={{ border: "1px solid #D9D9D9", borderRadius: "10px", height: "auto", width: "100%", padding: "20px", marginBottom: "30px" }}>
                                    {
                                        loginMessage.length > 0 &&
                                        <div class="alert alert-danger" role="alert">
                                            {loginMessage}
                                        </div>
                                    }
                                    <h1 style={{ marginTop: "50px", marginBottom: "20px" }}>Reset Password!</h1>
                                    <p style={{ color: "red", fontSize: "12px", textAlign: "start", marginBottom: "0px", marginTop: "10px" }}>{phoneMessage}</p>
                                    <input disabled={loading} value={phone} onChange={(e) => {
                                        const phoneRegex = /^\+?(\d{1,4})?[-.\s]?(\(?\d{1,4}\)?[-.\s]?)?(\d{1,4}[-.\s]?){1,4}$/;

                                        if (phoneRegex.test(e.target.value)) {
                                            setPhone(e.target.value)
                                            setPhoneMessage("")
                                        }
                                        else {
                                            setPhone(e.target.value)
                                            setPhoneMessage("Incorrect phone number!")
                                        }
                                    }} style={{ width: "100%", height: "78px", borderRadius: "10px", border: "1px solid #D9D9D9", outline: "none", padding: "10px", MozAppearance: "textfield" }} placeholder='Phone Number' />

                                    <button disabled={loading} style={{ width: "100%", height: "51px", marginTop: "20px", borderRadius: "10px", outline: "none", backgroundColor: "#16CDB7", border: "none", color: "white" }} onClick={() => forgetPasswordHandle()}>{loading ? <div class="spinner-border text-light" role="status"></div> : "Reset"}</button>
                                    <Link style={{ textDecoration: "none", display: "block", marginTop: "20px", fontSize: "14px" }} to="/register">Register</Link>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Sifremiunuttum;

export const LOGGED_IN = 'LOGGED_IN';
export const LOGGED_OUT = 'LOGGED_OUT';


export const login = (user,token) => ({
  type: LOGGED_IN,
  user: user,
  token: token
});


import { LOGGED_IN, LOGGED_OUT } from '../Actions/login';

const initialState = {
    user: {
        id: -1,
        name: "Sefa Gündoğdu",
        phone: "05495500509",
        mail: "gundogdusefaa@gmail.com",
        birthdate: "22.10.1995",
        image:null,
        isactive:false
    },
    isloggedin: false,
    token:""
};

const LoginReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGGED_IN:
            console.log("hello from reducer")
            return {
                user: action.user,
                isloggedin: true,
                token: action.token
            };
        case LOGGED_OUT:
            return {
                user: {
                    id: -1,
                    name: "",
                    phone: "",
                    mail: "",
                    birthdate: "",
                    image:null,
                    isactive:false
                },
                isloggedin: false,
                token:""
            }
        default:
            return state;
    }
};

export default LoginReducer;
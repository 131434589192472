
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from "react-toastify";
import axiosInstance from '../../Axios';
import Services from './Services';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Button, TextField } from '@mui/material';
import Personels from './Personels';
import Customers from './Customers';
import Messages from './Messages';
import Requests from './Requests';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { isMobile } from 'react-device-detect';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
function Business() {

    const authenticated = useSelector(state => state.login)

    const [businessList, setBusinessList] = useState([])
    const [selectedBusiness, setSelectedBusiness] = useState({
        id: -1,
        ownerid: -1,
        name: "Seçilmiş bir isim yok",
        phone: "Seçilmiş bir telefon yok",
        mail: "Seçilmiş bir mail yok",
        address: "Seçilmiş bir adres yok",
        city: "Şehir",
        area: "Yazılım Geliştirme",
        logo: null,
        isactive: false,
    })
    const [selectedTab, setSelectedTab] = useState(0)
    const [isAddBussOpen, setIsAddBussOpen] = useState(false)
    const [selectedImage, setSelectedImage] = useState(null)
    const [name, setName] = useState("")
    const [phone, setPhone] = useState("")
    const [mail, setMail] = useState("")
    const [address, setAddress] = useState("")
    const [city, setCity] = useState("Ankara")
    const [area, setArea] = useState("")
    const [cities, setCities] = useState([])
    const [isUpdateBussModal, setIsUpdateBussModal] = useState(false)

    const [isBussSaveLoading, setIsBussSaveLoading] = useState(false)
    const [isBussUpdateLoading, setIsBussUpdateLoading] = useState(false)

    const [isLoading, setIsLoading] = useState(false)

    const [updateBusiness, setUpdateBusiness] = useState(selectedBusiness != undefined ? JSON.parse(JSON.stringify(selectedBusiness)) : undefined)

    useEffect(() => {
        const getCities = async () => {

            try {
                const response = await axiosInstance.get('/cities');

                setCities(response.data)

            } catch (error) {

                toast.error("We are experiencing a connection problem. Try again later.")

            }
        }
        getCities()
    }, [])

    useEffect(() => {
        getBusinesses()
    }, [])

    useEffect(() => {
        setUpdateBusiness(selectedBusiness != undefined ? JSON.parse(JSON.stringify(selectedBusiness)) : undefined)
    }, [selectedBusiness])



    const getBusinesses = async () => {
        setIsLoading(true)
        try {
            const response = await axiosInstance.get('/users/' + authenticated.user.id + "/businesses");

            setBusinessList(response.data.businesses)

            if(selectedBusiness.id!=-1)
                setSelectedBusiness(response.data.businesses.find((item)=>selectedBusiness.id == item.id))
            else
                setSelectedBusiness(response.data.businesses[0])

            setIsLoading(false)
        } catch (error) {

            if (error.response != undefined) {
                setIsLoading(false)
                toast.error(error.response.data.error)
            }
            else {
                toast.error("We are experiencing a connection problem. Try again later.")
            }

        }

    }

    const saveBusiness = async () => {

        setIsBussSaveLoading(true)
        try {

            const response = await axiosInstance.post('/businesses',
                {
                    name,
                    phone,
                    mail,
                    address,
                    city,
                    area,
                    logo: selectedImage
                }
            );

            getBusinesses()

            setIsAddBussOpen(false)

            toast.success("Business Added and updated.")


        } catch (error) {

            if (error.response != undefined)
                toast.error(error.response.data.error)
            else {
                toast.error("We are experiencing a connection problem. Try again later.")
            }

        }

        setTimeout(() => {
            setIsBussSaveLoading(false)
        }, 2000)


    }

    const updateBusinessProfile = async () => {
        
        setIsBussUpdateLoading(true)
        try {

            const response = await axiosInstance.put('/businesses/' + selectedBusiness.id,
                updateBusiness
            );

            getBusinesses()

            setIsUpdateBussModal(false)

            setSelectedBusiness(businessList.find((item)=>item.id == selectedBusiness.id))

            toast.success("The business has been updated.")


        } catch (error) {

            if (error.response != undefined)
                toast.error(error.response.data.error)
            else {
                toast.error("We are experiencing a connection problem. Try again later.")
            }

        }

        setTimeout(() => {
            setIsBussUpdateLoading(false)
        }, 2000)


    }

    if (isLoading) {

        return (
            <div className="App">
                <div className="container-fluid">
                    <div className="row justify-content-center" style={{ marginBottom: "10px" }}>
                        <div className='col-11 col-sm-11 col-md-10 col-lg-6 col-xl-6' style={{ padding: "0px", zIndex: "10" }}>
                            <div style={{ backgroundColor: "white", height: "auto", border: "1px solid #EAEAEA", borderRadius: "20px" }}>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "20px" }}>
                                    <div style={{ width: "200px" }}>
                                        <Skeleton count={1} />
                                    </div>
                                    <div style={{ width: "200px" }}>
                                        <Skeleton count={1} />
                                    </div>
                                </div>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: isMobile ? "column" : "row" }}>
                                    <div><Skeleton style={{width:"150px",height:"150px",borderRadius:"150px"}}/></div>
                                    <div style={{ marginLeft: "20px" }}>
                                        <div><Skeleton count={1} /></div>
                                        <div style={{ marginBottom: "10px", width:"200px" }}><Skeleton count={1} /></div>
                                        <div style={{ marginBottom: "10px" , width:"200px"}}><Skeleton count={1} /></div>
                                        <div style={{ marginBottom: "10px", width:"200px" }}><Skeleton count={1} /></div>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "start" }}>
                                            <Skeleton count={1} />
                                        </div>
                                        <div style={{ textAlign: "start", marginTop: "20px", marginBottom: "0px" }}>
                                            <Skeleton count={2} />
                                            
                                        </div>
                                    </div>
                                </div>
                                <div style={{ padding: "20px", marginTop: "30px" }}>
                                    <div style={{ overflow: "auto", borderBottom: "1px solid #d9d9d9", paddingBottom: "10px", width:"100%" }}>
                                        <Skeleton style={{width:"100%"}} />
                                    </div>
                                    <div style={{marginTop:"100px"}}>
                                        <Skeleton count={6} style={{width:"100%"}}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
 

            </div>
        );


    }
    else {



        if (businessList.length == 0) {
            return (
                <div className="App">
                    <div className="container-fluid">
                        <div className="row justify-content-center" style={{ marginBottom: "10px" }}>
                            <div className='col-11 col-sm-11 col-md-10 col-lg-6 col-xl-6' style={{ padding: "0px", zIndex: "10" }}>
                                <div style={{ backgroundColor: "white", height: "700px", border: "1px solid #EAEAEA", borderRadius: "20px", display: "flex", alignItems: "center", justifyContent: "center" }}>

                                    <div>
                                        <h3>You haven't opened any business!</h3>
                                        <p>Start your business and don't keep your customers waiting.</p>
                                        <button style={{ border: "none", backgroundColor: "#16CDB7", padding: "5px", borderRadius: "10px", color: "white" }} onClick={() => setIsAddBussOpen(true)}><i style={{ color: "white" }} class="ri-add-line"></i>Add Business</button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        isAddBussOpen &&

                        <div style={{ position: "absolute", top: "0px", left: "0px", width: "100%", height: "100%", zIndex: "1000000000000000000000", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <div style={{ backgroundColor: "black", width: "100%", height: "100%", opacity: "0.4", position: "absolute", top: "0px", left: "0px", zIndex: "1000000000000000" }} onClick={() => setIsAddBussOpen(false)}></div>
                            <div style={{ width: "350px", height: "600px", zIndex: "1000000000000000000000", backgroundColor: "white", borderRadius: "20px", padding: "20px" }}>
                                <h2 style={{ textAlign: "start", color: "#969696", fontSize: "14px", marginBottom: "20px" }}>Add Business</h2>
                                <div style={{ position: "relative", height: "150px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <img src={selectedImage != null ? selectedImage : "defaultbuss.png"} style={{ width: "130px", height: "130px", borderRadius: "130px" }} />
                                    <input
                                        style={{ border: "1px solid red", position: "absolute", top: "0px", left: "0px", opacity: "0", zIndex: "10000", width: "100%", height: "100%", cursor: "pointer" }}
                                        type="file"
                                        id="fileInput"
                                        accept=".jpg,.jpeg,.png"
                                        onChange={(e) => {

                                            const file = e.target.files[0];
                                            const maxSizeInBytes = 10 * 1024 * 1024;
                                            if (file && file.size <= maxSizeInBytes) {


                                                const reader = new FileReader();

                                                reader.onload = () => {
                                                    const base64String = reader.result;
                                                    setSelectedImage(base64String);
                                                };

                                                if (file) {
                                                    reader.readAsDataURL(file);
                                                }
                                            }
                                            else {
                                                toast.error("Please choose profile photo less than 10MB")
                                            }

                                        }}
                                    />
                                </div>
                                <div>

                                    <input value={name} onChange={(e) => setName(e.target.value)} type='text' placeholder='Business Name' style={{ marginTop: "10px", width: "100%", height: "40px", borderRadius: "10px", padding: "5px", border: "1px solid #d9d9d9", outline: "none" }} />
                                    <input value={phone} onChange={(e) => setPhone(e.target.value)} type='text' placeholder='Business Phone' style={{ marginTop: "10px", width: "100%", height: "40px", borderRadius: "10px", padding: "5px", border: "1px solid #d9d9d9", outline: "none" }} />
                                    <input value={mail} onChange={(e) => setMail(e.target.value)} type='text' placeholder='Business E-Mail' style={{ marginTop: "10px", width: "100%", height: "40px", borderRadius: "10px", padding: "5px", border: "1px solid #d9d9d9", outline: "none" }} />
                                    <input value={address} onChange={(e) => setAddress(e.target.value)} type='text' placeholder='Business Address' style={{ marginTop: "10px", width: "100%", height: "40px", borderRadius: "10px", padding: "5px", border: "1px solid #d9d9d9", outline: "none" }} />
                                    <select value={city} onChange={(e) => setCity(e.target.value)} style={{display:"none", marginTop: "10px", width: "100%", height: "40px", borderRadius: "10px", padding: "5px", border: "1px solid #d9d9d9", outline: "none" }}>
                                        <option value={"Ankara"}>İşletme Şehir</option>
                                        {
                                            cities.map((city, index) => {
                                                return <option value={city.name}>{city.code}-{city.name}</option>
                                            })
                                        }
                                    </select>
                                    <input value={area} onChange={(e) => setArea(e.target.value)} type='text' placeholder='Business Area(Ex: Beauty and Care)' style={{ marginTop: "10px", width: "100%", height: "40px", borderRadius: "10px", padding: "5px", border: "1px solid #d9d9d9", outline: "none" }} />

                                </div>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "end", marginTop: "10px" }}>
                                    <button style={{ padding: "10px", borderRadius: "10px", backgroundColor: "#16CDB7", color: "white", border: "none" }} onClick={() => saveBusiness()} disabled={isBussSaveLoading}>
                                        {
                                            isBussSaveLoading ? <div class="spinner-border text-light" role="status"></div> : "Save"
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            )
        }
        else {
            return (
                <div className="App">
                    <div className="container-fluid">
                        <div className="row justify-content-center" style={{ marginBottom: "10px" }}>
                            <div className='col-11 col-sm-11 col-md-10 col-lg-6 col-xl-6' style={{ padding: "0px", zIndex: "10" }}>
                                <div style={{ backgroundColor: "white", height: "auto", border: "1px solid #EAEAEA", borderRadius: "20px" }}>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "20px" }}>
                                        <div>
                                            <Button variant="contained" style={{ border: "none", backgroundColor: "#16CDB7", padding: "10px", borderRadius: "10px", color: "white", fontSize: "12px" }} onClick={() => setIsAddBussOpen(true)}><i style={{ color: "white" }} class="ri-add-line"></i>Add Business</Button>
                                        </div>
                                        <div style={{ width: "200px" }}>
                                            <FormControl>
                                                <InputLabel id="demo-simple-select-label">Choose Business</InputLabel>
                                                <Select
                                                    style={{ width: "200px" }}
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    label="Choose Business"
                                                    value={selectedBusiness.id} onChange={(e) => {
                                                        var i;
                                                        for (i = 0; i < businessList.length; i++) {
                                                            if (businessList[i].id == e.target.value)
                                                                setSelectedBusiness(businessList[i])
                                                        }
                                                    }}>
                                                    {
                                                        businessList.map((business, index) => {
                                                            return (
                                                                <MenuItem value={business.id}><img src={business.logo != null ? business.logo : "defaultbuss.png"} style={{ width: "30px", height: "30px", border: "1px solid #d9d9d9", borderRadius: "40px", marginRight: "5px" }} />{business.name}</MenuItem>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: isMobile ? "column" : "row" }}>
                                        <div><img src={selectedBusiness.logo != null ? selectedBusiness.logo : "defaultbuss.png"} style={{ width: "150px", height: "150px", borderRadius: "100px", border: "1px solid #d9d9d9", margin: isMobile ? "20px" : "0px" }} /></div>
                                        <div style={{ marginLeft: "20px" }}>
                                            <div><p style={{ textAlign: "start", fontSize: "14px", marginBottom: "10px" }}><b>{selectedBusiness.name}</b></p></div>
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "start", fontSize: "14px", marginBottom: "10px" }}><i style={{ color: "#969696", marginRight: "5px" }} class="ri-map-pin-line"></i><p style={{ textAlign: "start", margin: "0px", color: "#969696" }}>{selectedBusiness.address}</p></div>
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "start", fontSize: "14px", marginBottom: "10px" }}><i style={{ color: "#969696", marginRight: "5px" }} class="ri-phone-line"></i><p style={{ textAlign: "start", margin: "0px", color: "#969696" }}>{selectedBusiness.phone}</p></div>
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "start", fontSize: "14px", marginBottom: "10px" }}><i style={{ color: "#969696", marginRight: "5px" }} class="ri-mail-line"></i><p style={{ textAlign: "start", margin: "0px", color: "#969696" }}>{selectedBusiness.mail}</p></div>
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "start" }}>
                                                <Button variant="contained" sx={{ fontSize: "14px" }} style={{ fontSize: "14px !important", marginRight: "10px", border: "none", backgroundColor: "#16CDB7", padding: "10px", borderRadius: "10px", color: "white" }} onClick={() => setIsUpdateBussModal(true)}> Update Profile</Button>
                                            </div>
                                            <div style={{ textAlign: "start", marginTop: "20px", marginBottom: "0px" }}>
                                                <p style={{ fontSize: "13px" }}>You can share your business and make appointments with your customers with the link below.</p>
                                                <Link style={{ textDecoration: "none", fontSize: "12px", color: "#aaa" }} to={"/randevual/" + selectedBusiness.id}>{"https://www.randevudefterim.com/randevual/" + selectedBusiness.id}</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ padding: "20px", marginTop: "30px" }}>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "start", overflow: "auto", borderBottom: "1px solid #d9d9d9", paddingBottom: "10px" }}>
                                            <button style={{ border: "none", backgroundColor: "transparent", color: selectedTab == 0 ? "white" : "#969696", backgroundColor: selectedTab == 0 ? "#16CDB7" : "transparent", padding: "10px", borderRadius: "10px", marginRight: "10px", fontSize: "14px" }} onClick={() => setSelectedTab(0)}>Employees</button>
                                            <button style={{ border: "none", backgroundColor: "transparent", color: selectedTab == 1 ? "white" : "#969696", backgroundColor: selectedTab == 1 ? "#16CDB7" : "transparent", padding: "10px", borderRadius: "10px", marginRight: "10px", fontSize: "14px" }} onClick={() => setSelectedTab(1)}>Customers</button>
                                            <button style={{ border: "none", backgroundColor: "transparent", color: selectedTab == 2 ? "white" : "#969696", backgroundColor: selectedTab == 2 ? "#16CDB7" : "transparent", padding: "10px", borderRadius: "10px", marginRight: "10px", fontSize: "14px" }} onClick={() => setSelectedTab(2)}>Services</button>
                                            {/* <button style={{ border: "none", backgroundColor: "transparent", color: selectedTab == 3 ? "white" : "#969696", backgroundColor: selectedTab == 3 ? "#16CDB7" : "transparent", padding: "10px", borderRadius: "10px", marginRight: "10px",fontSize:"14px" }} onClick={() => setSelectedTab(3)}>Yorumlar</button> */}
                                            <button style={{ border: "none", backgroundColor: "transparent", color: selectedTab == 4 ? "white" : "#969696", backgroundColor: selectedTab == 4 ? "#16CDB7" : "transparent", padding: "10px", borderRadius: "10px", marginRight: "10px", fontSize: "14px" }} onClick={() => setSelectedTab(4)}>Message Templates</button>
                                            <button style={{ border: "none", backgroundColor: "transparent", color: selectedTab == 5 ? "white" : "#969696", backgroundColor: selectedTab == 5 ? "#16CDB7" : "transparent", padding: "10px", borderRadius: "10px", marginRight: "10px", fontSize: "14px" }} onClick={() => setSelectedTab(5)}>Appointment Requests</button>                                        {/* <button style={{ border: "none", backgroundColor: "transparent", color: selectedTab == 5 ? "white" : "#969696", backgroundColor: selectedTab == 5 ? "#16CDB7" : "transparent", padding: "10px", borderRadius: "10px", marginRight: "10px",fontSize:"14px" }} onClick={() => setSelectedTab(5)}>Ayarlar</button> */}
                                        </div>

                                    </div>
                                    {
                                        selectedTab == 0 &&
                                        <Personels selectedBusiness={selectedBusiness} />
                                    }
                                    {
                                        selectedTab == 1 &&
                                        <Customers selectedBusiness={selectedBusiness} />
                                    }
                                    {
                                        selectedTab == 2 &&
                                        <Services selectedBusiness={selectedBusiness} />
                                    }
                                    {
                                        selectedTab == 4 &&
                                        <Messages selectedBusiness={selectedBusiness} />
                                    }
                                    {
                                        selectedTab == 5 &&
                                        <Requests selectedBusiness={selectedBusiness} />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        isAddBussOpen &&

                        <div style={{ position: "absolute", top: "0px", left: "0px", width: "100%", height: "100%", zIndex: "1000000000000000000000", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <div style={{ backgroundColor: "black", width: "100%", height: "100%", opacity: "0.4", position: "absolute", top: "0px", left: "0px", zIndex: "1000000000000000" }} onClick={() => setIsAddBussOpen(false)}></div>
                            <div style={{ width: "350px", height: "600px", zIndex: "1000000000000000000000", backgroundColor: "white", borderRadius: "20px", padding: "20px" }}>
                                <h2 style={{ textAlign: "start", color: "#969696", fontSize: "14px", marginBottom: "20px" }}>Add Business</h2>
                                <div style={{ position: "relative", height: "150px", display: "flex", alignItems: "center", justifyContent: "center" }}>

                                    <img src={selectedImage != null ? selectedImage : "defaultbuss.png"} style={{ width: "130px", height: "130px", borderRadius: "130px" }} />
                                    <input
                                        style={{ border: "1px solid red", position: "absolute", top: "0px", left: "0px", opacity: "0", zIndex: "10000", width: "100%", height: "100%", cursor: "pointer" }}
                                        type="file"
                                        id="fileInput"
                                        accept=".jpg,.jpeg,.png"
                                        onChange={(e) => {

                                            const file = e.target.files[0];
                                            const maxSizeInBytes = 10 * 1024 * 1024;
                                            if (file && file.size <= maxSizeInBytes) {


                                                const reader = new FileReader();

                                                reader.onload = () => {
                                                    const base64String = reader.result;
                                                    setSelectedImage(base64String);
                                                };

                                                if (file) {
                                                    reader.readAsDataURL(file);
                                                }
                                            }
                                            else {
                                                toast.error("Please choose profile photo less than 10MB")
                                            }

                                        }}
                                    />
                                </div>
                                <h2 style={{ textAlign: "center", color: "#969696", fontSize: "12px", marginBottom: "20px" }}>You can choose by clicking on the photo</h2>

                                <div>

                                    <input value={name} onChange={(e) => setName(e.target.value)} type='text' placeholder='Business Name' style={{ marginTop: "10px", width: "100%", height: "40px", borderRadius: "10px", padding: "5px", border: "1px solid #d9d9d9", outline: "none" }} />
                                    <input value={phone} onChange={(e) => setPhone(e.target.value)} type='text' placeholder='Business Phone' style={{ marginTop: "10px", width: "100%", height: "40px", borderRadius: "10px", padding: "5px", border: "1px solid #d9d9d9", outline: "none" }} />
                                    <input value={mail} onChange={(e) => setMail(e.target.value)} type='text' placeholder='Business Mail' style={{ marginTop: "10px", width: "100%", height: "40px", borderRadius: "10px", padding: "5px", border: "1px solid #d9d9d9", outline: "none" }} />
                                    <input value={address} onChange={(e) => setAddress(e.target.value)} type='text' placeholder='Business Address' style={{ marginTop: "10px", width: "100%", height: "40px", borderRadius: "10px", padding: "5px", border: "1px solid #d9d9d9", outline: "none" }} />
                                    <select value={city} onChange={(e) => setCity(e.target.value)} style={{display:"none", marginTop: "10px", width: "100%", height: "40px", borderRadius: "10px", padding: "5px", border: "1px solid #d9d9d9", outline: "none" }}>
                                        <option value={"Ankara"} >İşletme Şehir</option>
                                        {
                                            cities.map((city, index) => {
                                                return <option value={city.name}>{city.code}-{city.name}</option>
                                            })
                                        }
                                    </select>
                                    <input value={area} onChange={(e) => setArea(e.target.value)} type='text' placeholder='Business Area(Ex. Beauty and Care)' style={{ marginTop: "10px", width: "100%", height: "40px", borderRadius: "10px", padding: "5px", border: "1px solid #d9d9d9", outline: "none" }} />

                                </div>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "end", marginTop: "10px" }}>
                                    <button style={{ padding: "10px", borderRadius: "10px", backgroundColor: "#16CDB7", color: "white", border: "none" }} onClick={() => saveBusiness()} disabled={isBussSaveLoading}>
                                        {
                                            isBussSaveLoading ? <div class="spinner-border text-light" role="status"></div> : "Save"
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        isUpdateBussModal &&
                        <div style={{ position: "absolute", top: "0px", left: "0px", width: "100%", height: "100%", zIndex: "1000000000000000000000", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <div style={{ backgroundColor: "black", width: "100%", height: "100%", opacity: "0.4", position: "absolute", top: "0px", left: "0px", zIndex: "1000000000000000" }} onClick={() => { setIsUpdateBussModal(false) }}></div>
                            <div style={{ width: "350px", height: "auto", zIndex: "1000000000000000000000", backgroundColor: "white", borderRadius: "20px", padding: "20px" }}>
                                <h2 style={{ textAlign: "start", color: "#969696", fontSize: "14px", marginBottom: "20px" }}>Update Business</h2>                            <div style={{ border: "1px solid #EAEAEA", backgroundColor: "white", height: "auto", borderRadius: "20px", padding: "20px", position: "relative" }}>
                                    <img src={updateBusiness.logo != null && updateBusiness.logo != "default.png" ? updateBusiness.logo : "defaultbuss.png"} style={{ width: "200px", height: "200px", borderRadius: "100px", border: "1px solid #d9d9d9" }} />
                                    <input
                                        style={{ border: "1px solid red", position: "absolute", top: "0px", left: "0px", opacity: "0", zIndex: "10000", width: "100%", height: "100%", cursor: "pointer" }}
                                        type="file"
                                        id="fileInput"
                                        accept=".jpg,.jpeg,.png"
                                        onChange={(e) => {

                                            const file = e.target.files[0];
                                            const maxSizeInBytes = 10 * 1024 * 1024;
                                            if (file && file.size <= maxSizeInBytes) {


                                                const reader = new FileReader();

                                                reader.onload = () => {
                                                    const base64String = reader.result;
                                                    setUpdateBusiness({ ...updateBusiness, logo: base64String });
                                                };

                                                if (file) {
                                                    reader.readAsDataURL(file);
                                                }
                                            }
                                            else {
                                                toast.error("Please choose profile photo less than 10MB")
                                            }

                                        }}
                                    />
                                </div>
                                <h2 style={{ textAlign: "center", color: "#969696", fontSize: "12px", marginBottom: "20px" }}>You can choose by clicking on the photo</h2>

                                <input value={updateBusiness.name} onChange={(e) => setUpdateBusiness({ ...updateBusiness, name: e.target.value })} type='text' placeholder='Business Name' style={{ marginTop: "10px", width: "100%", height: "40px", borderRadius: "10px", padding: "5px", border: "1px solid #d9d9d9", outline: "none" }} />
                                <input value={updateBusiness.phone} onChange={(e) => setUpdateBusiness({ ...updateBusiness, phone: e.target.value })} type='text' placeholder='Business Phone' style={{ marginTop: "10px", width: "100%", height: "40px", borderRadius: "10px", padding: "5px", border: "1px solid #d9d9d9", outline: "none" }} />
                                <input value={updateBusiness.mail} onChange={(e) => setUpdateBusiness({ ...updateBusiness, mail: e.target.value })} type='text' placeholder='Business Mail' style={{ marginTop: "10px", width: "100%", height: "40px", borderRadius: "10px", padding: "5px", border: "1px solid #d9d9d9", outline: "none" }} />
                                <input value={updateBusiness.address} onChange={(e) => setUpdateBusiness({ ...updateBusiness, address: e.target.value })} type='text' placeholder='Business Address' style={{ marginTop: "10px", width: "100%", height: "40px", borderRadius: "10px", padding: "5px", border: "1px solid #d9d9d9", outline: "none" }} />
                                <select value={updateBusiness.city} onChange={(e) => setUpdateBusiness({ ...updateBusiness, city: e.target.value })} style={{ display:"none",marginTop: "10px", width: "100%", height: "40px", borderRadius: "10px", padding: "5px", border: "1px solid #d9d9d9", outline: "none" }}>
                                    <option value={"Ankara"}>İşletme Şehir</option>
                                    {
                                        cities.map((city, index) => {
                                            return <option value={city.name}>{city.code}-{city.name}</option>
                                        })
                                    }
                                </select>
                                <input value={updateBusiness.area} onChange={(e) => setUpdateBusiness({ ...updateBusiness, area: e.target.value })} type='text' placeholder='Business Area(Ex. Beauty and Care)' style={{ marginTop: "10px", width: "100%", height: "40px", borderRadius: "10px", padding: "5px", border: "1px solid #d9d9d9", outline: "none" }} />


                                <div style={{ display: "flex", alignItems: "center", justifyContent: "end", marginTop: "40px" }}>
                                    <Button variant="contained" style={{ padding: "10px", borderRadius: "10px", backgroundColor: "#16CDB7", color: "white", border: "none" }} disabled={isBussUpdateLoading} onClick={() => { updateBusinessProfile() }}>
                                        {
                                            isBussUpdateLoading ? <div class="spinner-border text-light" role="status"></div> : "Update"
                                        }
                                    </Button>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            );
        }
    }


}

export default Business;

import logo from './logo.svg';
import './App.css';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Switch, Link } from 'react-router-dom';
import Public from './Pages/Public';
import Private from './Pages/Private';
import NotFound from './Pages/NotFound';
import { login } from './Redux/Actions/login';
import { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { HelmetProvider } from 'react-helmet-async';

function App() {

  const dispatch = useDispatch();
  const authenticated = useSelector(state => state.login)

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (localStorage.getItem("token") && localStorage.getItem("user")) {
      dispatch(login(JSON.parse(localStorage.getItem("user")), localStorage.getItem("token")))

    }

    setTimeout(() => {
      setLoading(false)
    }, 1)
  }, [])


  if (loading) {
    return (
      <div style={{ width: "100%", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <div class="spinner-border text-info" role="status">
          </div>
          <p style={{ margin: "0px", marginLeft: "10px" }}>Yükleniyor Lütfen Bekleyiniz...</p>
        </div>
      </div>
    )
  }
  else {
    return (
      <HelmetProvider>
        <div>
          <Switch>
            <Route exact path="/" component={Public} />
            <Route path="/login" component={Public} />
            <Route path="/register" component={Public} />
            <Route path="/sifremiunuttum" component={Public} />
            <Route path="/sifresifirla" component={Public} />
            <Route path="/randevual/:id" component={Public} />

            <Route path="/blog" component={Public} />
            <Route path="/randevu-takip-programi" component={Public} />
            <Route path="/dis-hekimi-randevu-programi" component={Public} />
            <Route path="/guzellik-salonu-randevu-programi" component={Public} />
            <Route path="/diyetisyen-randevu-programi" component={Public} />
            <Route path="/berber-randevu-programi" component={Public} />
            <Route path="/guzellik-merkezi-randevu-programi" component={Public} />
            <Route path="/randevu-programi" component={Public} />
            <Route path="/online-randevu-programi" component={Public} />
            <Route path="/nail-art-kurslari" component={Public} />
            <Route path="/nail-art-ekipmanlari" component={Public} />
            <Route path="/randevu-defteri" component={Public} />
            <Route path="/dovme-malzemeleri" component={Public} />
            <Route path="/beslenme-makaleleri" component={Public} />
            <Route path="/beslenme-diyetetik-dergileri" component={Public} />
            <Route path="/randevu-yonetimi" component={Public} />
            <Route path="/hasta-memnuniyeti" component={Public} />
            <Route path="/guzellik-merkezi-cihazlar" component={Public} />
            <Route path="/hizmet-kalitesini-artirma" component={Public} />


            <Route path="/profil" component={Private} />
            <Route path="/randevularim" component={Private} />
            <Route path="/isletmeler" component={Private} />
            <Route path="/bildirimler" component={Private} />
            <Route path="/logout" component={Private} />
            <Route path="/kesfet" component={Private} />


            <Route path="*" component={NotFound} />


          </Switch>
          <ToastContainer position="bottom-right" style={{ zIndex: "10000000000000000000000000000000000000" }} />
        </div>
      </HelmetProvider>

    );
  }
}

export default App;


import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import axiosInstance from '../../Axios';
import { Button, TextField } from '@mui/material';

function Messages({ selectedBusiness }) {

    const [messages, setMessages] = useState([])
    const [isAddMessageModal, setIsAddMessageModal] = useState(false)
    const [messageTitle, setMessageTitle] = useState("")
    const [message, setMessage] = useState("")
    const [isSaveLoading, setIsSaveLoading] = useState(false)
    const [isUpdateLoading, setIsUpdateLoading] = useState(false)
    const [selectedMessage, setSelectedMessage] = useState({
        title: "",
        message: "",
        id: ""
    })


    const [isUpdateMessageModal, setIsUpdateMessageModal] = useState(false)



    const getMessages = async () => {

        try {
            const response = await axiosInstance.get('/businesses/' + selectedBusiness.id + "/messages");

            setMessages(response.data)


        } catch (error) {

            if (error.response != undefined)

                toast.error(error.response.data.error)
            else {
                toast.error("We are experiencing a connection problem. Try again later.")
            }

        }

    }

    useEffect(() => {
        getMessages()
    }, [selectedBusiness])

    const saveMesssage = async () => {

        setIsSaveLoading(true)
        try {

            const response = await axiosInstance.post('/businesses/' + selectedBusiness.id + "/messages",
                {
                    title: messageTitle,
                    message: message

                }
            );

            getMessages()

            setIsAddMessageModal(false)

            toast.success("Message Added.")


        } catch (error) {

            if (error.response != undefined)
                toast.error(error.response.data.error)
            else {
                toast.error("We are experiencing a connection problem. Try again later.")
            }

        }

        setTimeout(() => {
            setIsSaveLoading(false)
        }, 2000)


    }

    const updateMessage= async () => {

        setIsUpdateLoading(true)
        try {

            const response = await axiosInstance.put('/businesses/' + selectedBusiness.id + "/messages/" + selectedMessage.id,
                {
                    title: selectedMessage.title,
                    message: selectedMessage.message,

                }
            );

            getMessages()

            setIsUpdateMessageModal(false)

            toast.success("The message has been updated.")


        } catch (error) {

            if (error.response != undefined)
                toast.error(error.response.data.error)
            else {
                toast.error("We are experiencing a connection problem. Try again later.")
            }

        }

        setTimeout(() => {
            setIsUpdateLoading(false)
        }, 2000)


    }

    const deleteMessage = async (id) => {

        try {

            const response = await axiosInstance.delete('/businesses/' + selectedBusiness.id + "/messages/" + id);

            getMessages()


            toast.success("The message template has been deleted successfully.")


        } catch (error) {

            if (error.response != undefined)
                toast.error(error.response.data.error)
            else {
                toast.error("We are experiencing a connection problem. Try again later.")
            }

        }

    }

    return (
        <div className="App">
            <div style={{ display: "flex", alignItems: "center", justifyContent: "end", padding: "20px" }}>
                <Button variant="contained" style={{ border: "none", borderRadius: "10px", backgroundColor: "#16CDB7", color: "white", padding: "10px" }} onClick={() => setIsAddMessageModal(true)}><i class="ri-add-line"></i>Add Message</Button>
            </div>
            <div style={{ overflow: "auto", padding: "20px" }}>
                {
                    messages.length == 0 &&
                    <div style={{ marginBottom: "40px" }}>
                        We Couldn't Find Any Message Templates at Your Business!
                    </div>
                }
                {
                    messages.length > 0 &&

                    <table class="table" style={{ marginTop: "20px" }}>
                        <thead >
                            <tr>
                                <th scope="col" style={{ color: "#5c5c5c", fontSize: "12px", textAlign: "start", fontWeight: "normal" }}>Title</th>
                                <th scope="col" style={{ color: "#5c5c5c", fontSize: "12px", textAlign: "start", fontWeight: "normal" }}>Message</th>
                                <th scope="col" style={{ color: "#5c5c5c", fontSize: "12px", textAlign: "start", fontWeight: "normal" }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                messages.map((message, index) => {
                                    return (
                                        <tr>
                                            <td style={{ textAlign: "start", fontSize: "14px" }}>{message.title}</td>
                                            <td style={{ textAlign: "start", fontSize: "14px" }}>{message.message}</td>
                                            <td ><Button variant="contained" style={{ marginRight: "5px", border: "none", borderRadius: "10px", backgroundColor: "#16CDB7", color: "white", padding: "10px" }} onClick={() => {
                                                setIsUpdateMessageModal(true)
                                                setSelectedMessage(message)
                                            }}><i class="ri-pencil-line"></i></Button>
                                                <Button variant="contained" style={{ border: "none", borderRadius: "10px", backgroundColor: "#f06960", color: "white", padding: "10px" }}
                                                    onClick={() => {
                                                        deleteMessage(message.id)
                                                    }}
                                                ><i class="ri-delete-bin-line"></i></Button></td>
                                        </tr>
                                    )
                                })
                            }

                        </tbody>
                    </table>
                }
            </div>
            {
                isAddMessageModal &&
                <div style={{ position: "absolute", top: "0px", left: "0px", width: "100%", height: "100%", zIndex: "1000000000000000000000", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div style={{ backgroundColor: "black", width: "100%", height: "100%", opacity: "0.4", position: "absolute", top: "0px", left: "0px", zIndex: "1000000000000000" }} onClick={() => setIsAddMessageModal(false)}></div>
                    <div style={{ width: "350px", height: "auto", zIndex: "1000000000000000000000", backgroundColor: "white", borderRadius: "20px", padding: "20px" }}>
                    <h2 style={{ textAlign: "start", color: "#969696", fontSize: "14px", marginBottom: "20px" }}>Add Message</h2>

                        <div>

                            <TextField id="outlined-basic" label="Title" variant="outlined" value={messageTitle} onChange={(e) => setMessageTitle(e.target.value)} type='text' placeholder='Title' style={{ marginTop: "10px", width: "100%", height: "40px" }} />
                            <TextField id="outlined-basic" label="Message" variant="outlined" type="text" value={message} onChange={(e) => setMessage(e.target.value)} placeholder='Message' style={{ marginTop: "30px", width: "100%", height: "40px" }} />

                        </div>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "end", marginTop: "40px" }}>
                            <Button variant="contained" style={{ padding: "10px", borderRadius: "10px", backgroundColor: "#16CDB7", color: "white", border: "none" }} disabled={isSaveLoading} onClick={() => saveMesssage()}>
                                {
                                    isSaveLoading ? <div class="spinner-border text-light" role="status"></div> : "Save"
                                }
                            </Button>
                        </div>
                    </div>
                </div>
            }
            {
                isUpdateMessageModal &&
                <div style={{ position: "absolute", top: "0px", left: "0px", width: "100%", height: "100%", zIndex: "1000000000000000000000", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div style={{ backgroundColor: "black", width: "100%", height: "100%", opacity: "0.4", position: "absolute", top: "0px", left: "0px", zIndex: "1000000000000000" }} onClick={() => setIsUpdateMessageModal(false)}></div>
                    <div style={{ width: "350px", height: "auto", zIndex: "1000000000000000000000", backgroundColor: "white", borderRadius: "20px", padding: "20px" }}>
                    <h2 style={{ textAlign: "start", color: "#969696", fontSize: "14px", marginBottom: "20px" }}>Update Message</h2>

                        <div>

                            <TextField id="outlined-basic" label="Title" variant="outlined" value={selectedMessage.title} onChange={(e) => setSelectedMessage({
                                ...selectedMessage,
                                title: e.target.value
                            })} type='text' placeholder='Title' style={{ marginTop: "10px", width: "100%", height: "40px" }} />
                            <TextField id="outlined-basic" label="Message" variant="outlined" type="text" value={selectedMessage.message} onChange={(e) => setSelectedMessage({
                                ...selectedMessage,
                                message: e.target.value
                            })} placeholder='Message' style={{ marginTop: "30px", width: "100%", height: "40px" }} />

                        </div>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "end", marginTop: "40px" }}>
                            <Button variant="contained" style={{ padding: "10px", borderRadius: "10px", backgroundColor: "#16CDB7", color: "white", border: "none" }} disabled={isUpdateLoading} onClick={() => updateMessage()}>
                                {
                                    isUpdateLoading ? <div class="spinner-border text-light" role="status"></div> : "Save"
                                }
                            </Button>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default Messages;

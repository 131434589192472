
import { Helmet } from 'react-helmet-async';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import MainMenu from '../../../Components/MainMenu';

function DisHekimiRandevuProgramı() {

    return (
        <div style={{ height: "auto", overflow: "auto" }}>
            <Helmet>
                <title>Diş Hekimi Randevu Programı</title>
                <meta name='description' content='Diş hekimliği pratiğinde hasta randevularını etkili bir şekilde yönetmek ve takip etmek çok önemlidir. İşte tam bu noktada devreye giren Randevu Defterim uygulaması diş hekimi randevu programıdır ve diş hekimlerinin tüm randevu ihtiyaçlarını karşılayacak harika bir çözüm sunuyor.' />
                <meta property="og:title" content="Diş Hekimi Randevu Programı"></meta>
                <meta property="og:description" content="Diş hekimliği pratiğinde hasta randevularını etkili bir şekilde yönetmek ve takip etmek çok önemlidir. İşte tam bu noktada devreye giren Randevu Defterim uygulaması diş hekimi randevu programıdır ve diş hekimlerinin tüm randevu ihtiyaçlarını karşılayacak harika bir çözüm sunuyor."></meta>
                <meta property="og:image" content="https://www.randevudefterim.com/icon.png"></meta>
                <meta property="og:url" content="https://www.randevudefterim.com/dis-hekimi-randevu-programi"></meta>
                <meta name="twitter:title" content="Diş Hekimi Randevu Programı"></meta>
                <meta name="twitter:description" content="Diş hekimliği pratiğinde hasta randevularını etkili bir şekilde yönetmek ve takip etmek çok önemlidir. İşte tam bu noktada devreye giren Randevu Defterim uygulaması diş hekimi randevu programıdır ve diş hekimlerinin tüm randevu ihtiyaçlarını karşılayacak harika bir çözüm sunuyor."></meta>
                <meta name="twitter:url" content="https://www.randevudefterim.com/icon.png"></meta>
                <meta name="keywords" content="diş hekimi randevu programı, dişçi randevusu, diş hekimi yazılımı, diş kliniği randevu, online diş randevusu, diş randevu takibi, diş hekimi uygulaması, hasta randevu yönetimi, randevu defterim"/>

            </Helmet>
            <MainMenu type={2} />
            <div className='container-fluid' style={{ marginTop: "100px", marginBottom: "100px" }}>
                <div className='row justify-content-center'>
                    <div className='col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8'>
                        <h1>Diş Hekimi Randevu Programı</h1>
                        <p>Diş hekimliği pratiğinde hasta randevularını etkili bir şekilde yönetmek ve takip etmek çok önemlidir. İşte tam bu noktada devreye giren Randevu Defterim uygulaması, diş hekimlerinin tüm randevu ihtiyaçlarını karşılayacak harika bir çözüm sunuyor.</p>

                        <h2>Randevu Defterim Nedir?</h2>
                        <p>Randevu Defterim, diş hekimlerinin kolayca kullanabileceği kapsamlı bir randevu takip programıdır. Bu uygulama sayesinde, diş hekimleri hızlıca randevu oluşturabilir, hastalarını kaydedebilir ve ücretsiz hatırlatma e-postaları gönderebilir. Üstelik, tüm bu hizmetler tamamen ücretsizdir.</p>

                        <h2>Randevu Defterim'in Özellikleri</h2>
                        <ul>
                            <li><strong>Sınırsız Randevu Ekleme:</strong> Kliniğinizin kapasitesi ne olursa olsun, sınırsız sayıda randevu ekleyebilir ve yönetebilirsiniz.</li>
                            <li><strong>Sınırsız Personel Ekleme:</strong> Kliniğinizdeki tüm diş hekimlerini ve personeli sisteme ekleyebilir, her biri için ayrı randevu takvimi oluşturabilirsiniz.</li>
                            <li><strong>Hasta Yönetimi:</strong> Hastalarınızın bilgilerini detaylı bir şekilde kaydedebilir, geçmiş tedavileri ve randevuları takip edebilir ve gelecekteki randevuları kolayca planlayabilirsiniz.</li>
                            <li><strong>Ücretsiz E-posta Gönderme:</strong> Hastalarınıza randevu hatırlatmaları ve bilgilendirme mesajları gönderebilir, hasta memnuniyetini ve sadakatini artırabilirsiniz.</li>
                            <li><strong>Kullanıcı Dostu Arayüz:</strong> Basit ve sezgisel arayüzü sayesinde, uygulamayı kolayca kullanabilir ve hızlı bir şekilde randevularınızı yönetebilirsiniz.</li>
                        </ul>

                        <h2>Neden Randevu Defterim?</h2>
                        <p>Randevu Defterim, diş kliniğinizin operasyonel verimliliğini artırmak ve hasta memnuniyetini en üst seviyeye çıkarmak için tasarlanmıştır. Ücretsiz olması, sınırsız randevu ve personel ekleme imkanı sunması gibi avantajlarıyla rakiplerinden ayrılmaktadır. Kliniğiniz için en uygun randevu takip programı olan Randevu Defterim’i kullanarak zamanınızı daha verimli kullanabilir ve hastalarınıza daha iyi hizmet verebilirsiniz.</p>

                        <p>Daha fazla bilgi almak ve hemen kullanmaya başlamak için <a href="https://www.randevudefterim.com">Randevu Defterim</a> web sitesini ziyaret edebilirsiniz.</p>

                        <p>Randevu yönetiminde devrim yaratacak bu uygulamayı denemek için ne bekliyorsunuz? Diş kliniğinizin ihtiyaç duyduğu tüm özellikleri bir arada sunan Randevu Defterim ile tanışın ve hasta memnuniyetinizi artırın!</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DisHekimiRandevuProgramı;

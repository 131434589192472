
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import Home from './Home';
import Login from './Login';
import Register from './Register';
import Sifremiunuttum from './Sifremiunuttum';
import Resetpassword from './Resetpassword';
import RandevuTakipProgramı from './blogs/RandevuTakipProgramı';
import DisHekimiRandevuProgramı from './blogs/DisHekimiRandevuProgramı';
import GuzellikSalonuRandevuProgramı from './blogs/GuzellikSalonuRandevuProgramı';
import DiyetisyenRandevuProgramı from './blogs/DiyetisyenRandevuProgramı';
import BerberRandevuProgramı from './blogs/BerberRandevuProgramı';
import GuzellikMerkeziRandevuProgramı from './blogs/GuzellikMerkeziRandevuProgramı';
import Businesses from './Businesses';
import RandevuProgramı from './blogs/RandevuProgramı';
import Blog from './blogs';
import OnlineRandevuProgramı from './blogs/OnlineRandevuProgramı';
import NailartKursları from './blogs/NailartKursları';
import NailartEkipmanları from './blogs/NailartEkipmanları';
import RandevuDefteri from './blogs/RandevuDefteri';
import DovmeMalzemeleri from './blogs/DovmeMalzemeleri';
import BeslenmeMakale from './blogs/BeslenmeMakale';
import BeslenmeDiyetetikDergileri from './blogs/BeslenmeDiyetetikDergileri';
import RandevuYonetimi from './blogs/RandevuYonetimi';
import HastaMemnuniyeti from './blogs/HastaMemnuniyeti';
import GuzellikCihaz from './blogs/GuzellikCihaz';
import HizmetKalitesi from './blogs/HizmetKalitesi';

function Public() {

  return (
    <div>

      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/sifremiunuttum" component={Sifremiunuttum} />
        <Route exact path="/sifresifirla" component={Resetpassword} />
        <Route exact path="/randevual/:id" component={Businesses} />

        <Route exact path="/blog" component={Blog} />
        <Route exact path="/randevu-takip-programi" component={RandevuTakipProgramı} />
        <Route exact path="/dis-hekimi-randevu-programi" component={DisHekimiRandevuProgramı} />
        <Route exact path="/guzellik-salonu-randevu-programi" component={GuzellikSalonuRandevuProgramı} />
        <Route exact path="/diyetisyen-randevu-programi" component={DiyetisyenRandevuProgramı} />
        <Route exact path="/berber-randevu-programi" component={BerberRandevuProgramı} />
        <Route exact path="/guzellik-merkezi-randevu-programi" component={GuzellikMerkeziRandevuProgramı} />
        <Route exact path="/randevu-programi" component={RandevuProgramı} />
        <Route exact path="/online-randevu-programi" component={OnlineRandevuProgramı} />
        <Route exact path="/nail-art-kurslari" component={NailartKursları} />
        <Route exact path="/nail-art-ekipmanlari" component={NailartEkipmanları} />
        <Route exact path="/randevu-defteri" component={RandevuDefteri} />
        <Route exact path="/dovme-malzemeleri" component={DovmeMalzemeleri} />
        <Route exact path="/beslenme-makaleleri" component={BeslenmeMakale} />
        <Route exact path="/beslenme-diyetetik-dergileri" component={BeslenmeDiyetetikDergileri} />
        <Route exact path="/randevu-yonetimi" component={RandevuYonetimi} />
        <Route exact path="/hasta-memnuniyeti" component={HastaMemnuniyeti} />
        <Route exact path="/guzellik-merkezi-cihazlar" component={GuzellikCihaz} />
        <Route exact path="/hizmet-kalitesini-artirma" component={HizmetKalitesi} />


      </Switch>

    </div>
  );
}

export default Public;

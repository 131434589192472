
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import axiosInstance from '../../Axios';
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { isMobile } from 'react-device-detect';

function Requests({ selectedBusiness }) {

    const [appointments, setAppointments] = useState([])
    const [isUpdateLoading, setIsUpdateLoading] = useState(false)


    const getAppointments = async (id) => {

        try {

            const response = await axiosInstance.get('/businesses/' + selectedBusiness.id + "/requests");

            setAppointments(response.data)


        } catch (error) {

            if (error.response != undefined)
                toast.error(error.response.data.error)
            else {
                toast.error("We are experiencing a connection problem. Try again later.")
            }

        }

    }

    console.log(appointments, "123123")

    useEffect(() => {
        getAppointments()
    }, [])


    const updateAppointment = async (appointmentid) => {

        setIsUpdateLoading(true)
        try {

            const response = await axiosInstance.put('/businesses/' + selectedBusiness.id + "/requests/" + appointmentid);

            getAppointments()

            setIsUpdateLoading(false)
            toast.success("An appointment has been made.")


        } catch (error) {
            setIsUpdateLoading(false)
            if (error.response != undefined)
                toast.error(error.response.data.error)
            else {
                toast.error("We are experiencing a connection problem. Try again later.")
            }

        }

    }

    const deleteAppointment = async (id, employeeid) => {

        try {

            const response = await axiosInstance.delete('/businesses/' + selectedBusiness.id + "/personels/" + employeeid + "/appointments/" + id);

            getAppointments()

            toast.success("The appointment has been deleted successfully.")

        } catch (error) {

            if (error.response != undefined)
                toast.error(error.response.data.error)
            else {
                toast.error("We are experiencing a connection problem. Try again later.")
            }

        }

    }


    return (
        <div className="App">
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "20px" }}>
            </div>
            <div style={{ overflow: "auto", padding: "20px" }}>
                {
                    appointments.length == 0 &&
                    <div style={{ marginBottom: "40px" }}>
                        You do not request any appointments.
                    </div>
                }
                {
                    appointments.length > 0 &&

                    <table class="table" style={{ marginTop: "20px" }}>
                        <thead >
                            <tr>
                                <th scope="col" style={{ color: "#5c5c5c", fontSize: "12px", textAlign: "start", fontWeight: "normal" }}>Customer</th>
                                <th scope="col" style={{ color: "#5c5c5c", fontSize: "12px", textAlign: "start", fontWeight: "normal" }}>Employee</th>
                                <th scope="col" style={{ color: "#5c5c5c", fontSize: "12px", textAlign: "start", fontWeight: "normal" }}>Date</th>
                                <th scope="col" style={{ color: "#969696", fontSize: "14px" }}></th>
                                <th scope="col" style={{ color: "#969696", fontSize: "14px" }}></th>

                            </tr>
                        </thead>
                        <tbody>
                            {
                                appointments.map((appointment, index) => {
                                    return (
                                        <tr>

                                            <td style={{ textAlign: "start", fontSize: "12px" }}><b>{appointment.name}</b> {appointment.phone}</td>
                                            <td style={{ textAlign: "start", fontSize: "12px" }}><img src={appointment.empimage != null ? appointment.empimage : "default.png"} style={{ width: "20px", height: "20px", borderRadius: "20px", display: isMobile ? "none" : "initial" }} />{appointment.empname}</td>
                                            <td style={{ textAlign: "start", fontSize: "12px" }}>{appointment.date.split("T")[0]}  {appointment.starttime}-{appointment.endtime}</td>

                                            <td style={{ textAlign: "start", fontSize: "14px" }}>
                                                <Button
                                                    sx={{
                                                        color: "white", padding: "10px",
                                                        border: "none", borderRadius: "10px",
                                                        backgroundColor: "#16CDB7",      // disabled durumunda metin rengi
                                                        '&.Mui-disabled': {
                                                            backgroundColor: '#ccc', // disabled durumunda arka plan rengi
                                                            color: 'white',           // disabled durumunda metin rengi
                                                        },
                                                        fontSize: "9px",
                                                        marginBottom:isMobile?"5px":"0px",
                                                        marginRight:isMobile?"0px":"5px"
                                                    }}

                                                    variant='contained' onClick={() => {
                                                        updateAppointment(appointment.appointmentid)
                                                    }}>
                                                    {
                                                        isUpdateLoading ? <div class="spinner-border text-light" role="status"></div> : "ConfIrm"
                                                    }
                                                </Button>
                                                <Button
                                                    sx={{
                                                        color: "white", padding: "10px",
                                                        border: "none", borderRadius: "10px",
                                                        backgroundColor: "#16CDB7",      // disabled durumunda metin rengi
                                                        '&.Mui-disabled': {
                                                            backgroundColor: '#ccc', // disabled durumunda arka plan rengi
                                                            color: 'white',           // disabled durumunda metin rengi
                                                        },
                                                        fontSize: "9px"
                                                    }}
                                                    variant="contained" style={{ border: "none", borderRadius: "10px", backgroundColor: "#f06960", color: "white", padding: "10px" }} onClick={() => { deleteAppointment(appointment.appointmentid, appointment.empid); }}>
                                                    Cancel
                                                </Button>
                                            </td>

                                        </tr>
                                    )
                                })
                            }

                        </tbody>
                    </table>
                }
            </div>
        </div>
    );
}

export default Requests;

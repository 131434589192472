
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import MainMenu from '../../Components/MainMenu';
import { Helmet } from 'react-helmet-async';
import axiosInstance from '../../Axios';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';

function Home() {

  const [buss1, setBuss1] = useState(undefined)
  const [buss2, setBuss2] = useState(undefined)
  const [buss3, setBuss3] = useState(undefined)

  useEffect(() => {
    // getBusiness1(14)
    // getBusiness2(58)
    // getBusiness3(56)
  }, [])

  const getBusiness1 = async (id) => {

    try {
      const response = await axiosInstance.get('/businesses/' + id);

      setBuss1(response.data)


    } catch (error) {

      if (error.response != undefined)

        toast.error(error.response.data.error)
      else {
        toast.error("Bağlantı Sorunu yaşıyoruz. Daha sonra tekrar deneyiniz.")
      }

    }

  }

  const getBusiness2 = async (id) => {

    try {
      const response = await axiosInstance.get('/businesses/' + id);

      setBuss2(response.data)


    } catch (error) {

      if (error.response != undefined)

        toast.error(error.response.data.error)
      else {
        toast.error("Bağlantı Sorunu yaşıyoruz. Daha sonra tekrar deneyiniz.")
      }

    }

  }

  const getBusiness3 = async (id) => {

    try {
      const response = await axiosInstance.get('/businesses/' + id);

      setBuss3(response.data)


    } catch (error) {

      if (error.response != undefined)

        toast.error(error.response.data.error)
      else {
        toast.error("Bağlantı Sorunu yaşıyoruz. Daha sonra tekrar deneyiniz.")
      }

    }

  }


  return (
    <div style={{ height: "auto", overflow: "hidden" }}>
      <Helmet>
        <title>Randevu Defterim | Randevu Programı</title>
        <meta name='description' content='Türkiyenin Ücretsiz Randevu takip programı Randevu defterim ile randevularınızı ücretsiz bir şekilde oluşturabilir. Sınırsız personel ekleyebilir. Müşterilerinize e-posta yollayabilirsiniz.' />
        <meta name='description' content='Türkiyenin Ücretsiz Randevu takip Programı Randevu defterim ile randevularınızı ücretsiz bir şekilde oluşturabilir. Sınırsız personel ekleyebilir. Müşterilerinize e-posta yollayabilirsiniz.' />
        <meta property="og:title" content="Randevu Takip Programı"></meta>
        <meta property="og:description" content="Türkiyenin Ücretsiz Randevu takip sistemi Randevu defterim ile randevularınızı ücretsiz bir şekilde oluşturabilir. Sınırsız personel ekleyebilir. Müşterilerinize e-posta yollayabilirsiniz."></meta>
        <meta property="og:image" content="https://www.randevudefterim.com/icon.png"></meta>
        <meta property="og:url" content="https://www.randevudefterim.com/randevu-takip-programi"></meta>
        <meta name="twitter:title" content="Randevu Takip Programı"></meta>
        <meta name="twitter:description" content="Türkiyenin Ücretsiz Randevu takip Programı Randevu defterim ile randevularınızı ücretsiz bir şekilde oluşturabilir. Sınırsız personel ekleyebilir. Müşterilerinize e-posta yollayabilirsiniz."></meta>
        <meta name="twitter:url" content="https://www.randevudefterim.com/icon.png"></meta>
        <meta name="keywords" content="randevu takip programı, randevu yönetimi, online randevu, randevu uygulaması, randevu takibi, randevu planlama, randevu yazılımı, müşteri randevusu, işletme randevusu, randevu defterim" />
      </Helmet>
      <MainMenu type={2} />



      <div className='container-fluid' style={{ marginTop: "50px", marginBottom: "50px" }}>
        <div className='row justify-content-center'>
          <div className='col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8'>
            <div className='container-fluid'>
              <h2 style={{ textAlign: "start", fontSize: "34px", marginBottom: "40px" }}>What Can You Do?</h2>
              <div className='row justify-content-center'>
                <div className='col-12 col-sm-12 col-md-10 col-lg-3 col-xl-3' style={{ marginTop: "10px" }}>
                  <div style={{ border: "1px solid #d9d9d9", height: "185px", borderRadius: "10px", padding: "10px" }}>
                    <div style={{ textAlign: "start" }}><i style={{ fontSize: "32px", color: "#50aff2" }} class="ri-mail-line"></i></div>
                    <h3 style={{ fontSize: "18px", textAlign: "start", fontWeight: "bold" }}>Create Unlimited Appointments</h3>
                    <p style={{ fontSize: "14px", textAlign: "start", fontWeight: "normal" }}>You can create unlimited appointments for your customers.</p>
                  </div>
                </div>
                <div className='col-12 col-sm-12 col-md-10 col-lg-3 col-xl-3' style={{ marginTop: "10px" }}>
                  <div style={{ border: "1px solid #d9d9d9", height: "185px", borderRadius: "10px", padding: "10px" }}>
                    <div style={{ textAlign: "start" }}><i style={{ fontSize: "32px", color: "green" }} class="ri-whatsapp-line"></i></div>
                    <h3 style={{ fontSize: "18px", textAlign: "start", fontWeight: "bold" }}>Send Whatsapp Message</h3>
                    <p style={{ fontSize: "14px", textAlign: "start", fontWeight: "normal" }}>Showcase the services you offer to your customers.</p>
                  </div>
                </div>
                <div className='col-12 col-sm-12 col-md-10 col-lg-3 col-xl-3' style={{ marginTop: "10px" }}>
                  <div style={{ border: "1px solid #d9d9d9", height: "185px", borderRadius: "10px", padding: "10px" }}>
                    <div style={{ textAlign: "start" }}><i style={{ fontSize: "32px", color: "#16CDB7" }} class="ri-gift-line"></i></div>
                    <h3 style={{ fontSize: "18px", textAlign: "start", fontWeight: "bold" }}>Manage Your Branches from One Place</h3>
                    <p style={{ fontSize: "14px", textAlign: "start", fontWeight: "normal" }}>SYou can create an unlimited business profiles and manage your branches from one place.</p>
                  </div>
                </div>
                <div className='col-12 col-sm-12 col-md-10 col-lg-3 col-xl-3' style={{ marginTop: "10px" }}>
                  <div style={{ border: "1px solid #d9d9d9", height: "185px", borderRadius: "10px", padding: "10px" }}>
                    <div style={{ textAlign: "start" }}><i style={{ fontSize: "32px", color: "#c24df0" }} class="ri-mail-line"></i></div>
                    <h3 style={{ fontSize: "18px", textAlign: "start", fontWeight: "bold" }}>Automatic messages</h3>
                    <p style={{ fontSize: "14px", textAlign: "start", fontWeight: "normal" }}>Send automatic messages to your customers when they make their appointments.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {
        buss1 != undefined && buss2 != undefined && buss3 != undefined &&

        <div className='container-fluid' style={{ marginTop: "50px", marginBottom: "50px" }}>
          <div className='row justify-content-center'>
            <div className='col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8'>
              <div className='container-fluid' style={{ marginBottom: "30px", marginTop: "50px" }}>
                <h2 style={{ textAlign: "start", fontSize: "34px", marginBottom: "10px" }}>Aramıza katılan son üyelerimiz!</h2>
                <p style={{ textAlign: "start", fontSize: "16px", marginBottom: "20px" }}>Kayıt olarak sen de doğru tercihi yapabilirsin!</p>
                <div className='row'>
                  <div className='col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4' style={{ marginTop: "10px" }}>
                    <div style={{ border: "1px solid #d9d9d9", padding: "10px", borderRadius: 10, paddingBottom: "30px", textAlign: "center" }}>
                      <div style={{ fontSize: "16px", color: "white" }}>-</div>
                      <div style={{ color: "#F0BB40", fontSize: "62px" }}><img src={buss1.logo} style={{ width: "100px", height: "100px", borderRadius: "100px" }} /></div>
                      <div style={{ fontSize: "24px", fontWeight: "bold" }}>{buss1.name}</div>
                      <div style={{ fontSize: "16px" }}>{buss1.area}</div>
                      <div style={{ fontSize: "16px" }}><Link href={"/randevual/" + buss1.id}></Link></div>

                      <div style={{ fontSize: "14px", fontWeight: "bold", marginTop: "10px" }}><a href={"/randevual/" + buss1.id} target="_blank" rel="noopener noreferrer" style={{ backgroundColor: "#16CDB7", textDecoration: "none", color: "white", padding: 10, borderRadius: 10 }}> Randevu Al</a></div>
                    </div>
                  </div>
                  <div className='col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4' style={{ marginTop: "10px" }}>
                    <div style={{ border: "1px solid #d9d9d9", padding: "10px", borderRadius: 10, paddingBottom: "30px", textAlign: "center" }}>
                      <div style={{ fontSize: "16px", color: "white" }}>-</div>
                      <div style={{ color: "#F0BB40", fontSize: "62px" }}><img src={buss2.logo} style={{ width: "100px", height: "100px", borderRadius: "100px" }} /></div>
                      <div style={{ fontSize: "24px", fontWeight: "bold" }}>{buss2.name}</div>
                      <div style={{ fontSize: "16px" }}>{buss2.area}</div>
                      <div style={{ fontSize: "16px" }}><Link href={"/randevual/" + buss2.id}></Link></div>

                      <div style={{ fontSize: "14px", fontWeight: "bold", marginTop: "10px" }}><a href={"/randevual/" + buss2.id} target="_blank" rel="noopener noreferrer" style={{ backgroundColor: "#16CDB7", textDecoration: "none", color: "white", padding: 10, borderRadius: 10 }}> Randevu Al</a></div>
                    </div>
                  </div>
                  <div className='col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4' style={{ marginTop: "10px" }}>
                    <div style={{ border: "1px solid #d9d9d9", padding: "10px", borderRadius: 10, paddingBottom: "30px", textAlign: "center" }}>
                      <div style={{ fontSize: "16px", color: "white" }}>-</div>
                      <div style={{ color: "#F0BB40", fontSize: "62px" }}><img src={buss3.logo} style={{ width: "100px", height: "100px", borderRadius: "100px" }} /></div>
                      <div style={{ fontSize: "24px", fontWeight: "bold" }}>{buss3.name}</div>
                      <div style={{ fontSize: "16px" }}>{buss3.area}</div>
                      <div style={{ fontSize: "16px" }}><Link href={"/randevual/" + buss3.id}></Link></div>

                      <div style={{ fontSize: "14px", fontWeight: "bold", marginTop: "10px" }}><a href={"/randevual/" + buss3.id} target="_blank" rel="noopener noreferrer" style={{ backgroundColor: "#16CDB7", textDecoration: "none", color: "white", padding: 10, borderRadius: 10 }}> Randevu Al</a></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      } */}
      <div className='container-fluid' style={{ marginTop: "50px", marginBottom: "50px" }}>
        <div className='row justify-content-center'>
          <div className='col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8'>
            <div className='container-fluid' style={{ marginBottom: "30px", marginTop: "50px" }}>
              <h2 style={{ textAlign: "start", fontSize: "34px", marginBottom: "10px" }}>Buy coins and use unlimited</h2>
              <p style={{ textAlign: "start", fontSize: "16px", marginBottom: "20px" }}>Corporate companies use Whatsapp. Become a member too and send messages to your customers via WhatsApp!</p>
              <div className='row'>
                <div className='col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3' style={{ marginTop: "10px" }}>
                  <div style={{ border: "1px solid #d9d9d9", padding: "10px", borderRadius: 10, paddingBottom: "30px", textAlign: "center" }}>
                    <div style={{ fontSize: "16px", color: "white" }}>-</div>
                    <div style={{ fontSize: "24px", fontWeight: "bold" }}>Free</div>
                    <div style={{ fontSize: "16px" }}><i style={{ color: "green" }} class="ri-check-line"></i>Unlimited Business</div>
                    <div style={{ fontSize: "16px" }}><i style={{ color: "green" }} class="ri-check-line"></i>Unlimited Staff</div>
                    <div style={{ fontSize: "16px" }}><i style={{ color: "green" }} class="ri-check-line"></i>Unlimited Appointments</div>
                    <div style={{ fontSize: "16px" }}><i style={{ color: "red" }} class="ri-close-line"></i>Sending Email</div>
                    <div style={{ fontSize: "16px" }}><i style={{ color: "red" }} class="ri-close-line"></i>Sending Whatsapp Message</div>
                    <div style={{ fontSize: "16px" }}><i style={{ color: "red" }} class="ri-close-line"></i>Automatic Messages</div>
                    <div style={{ fontSize: "18px", fontWeight: "bold", marginTop: "10px",textDecoration:"line-through",color:"black",height:"20px"  }}></div>
                    <div style={{ fontSize: "24px", fontWeight: "bold" }}>0$</div>
                    <div style={{ fontSize: "24px", fontWeight: "bold", marginTop: "10px" }}><a href={"https://www.randevudefterim.com/register"} rel="noopener noreferrer" style={{ backgroundColor: "#16CDB7", textDecoration: "none", color: "white", padding: 10, borderRadius: 10 }}>Register</a></div>
                  </div>
                </div>
                <div className='col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3' style={{ marginTop: "10px" }}>
                  <div style={{ border: "1px solid #d9d9d9", padding: "10px", borderRadius: 10, paddingBottom: "30px", textAlign: "center" }}>
                    <div style={{ fontSize: "16px", color: "white" }}>-</div>
                    <div style={{ fontSize: "24px", fontWeight: "bold" }}>1000 COIN</div>
                    <div style={{ fontSize: "16px" }}><i style={{ color: "green" }} class="ri-check-line"></i>Unlimited Business</div>
                    <div style={{ fontSize: "16px" }}><i style={{ color: "green" }} class="ri-check-line"></i>Unlimited Staff</div>
                    <div style={{ fontSize: "16px" }}><i style={{ color: "green" }} class="ri-check-line"></i>Unlimited Appointments</div>
                    <div style={{ fontSize: "16px" }}><i style={{ color: "green" }} class="ri-check-line"></i>1000 Sending Email</div>
                    <div style={{ fontSize: "16px" }}><i style={{ color: "green" }} class="ri-check-line"></i>1000 Sending Whatsapp Message</div>
                    <div style={{ fontSize: "16px" }}><i style={{ color: "green" }} class="ri-check-line"></i>Automatic Messages</div>

                    <div style={{ fontSize: "18px", fontWeight: "bold", marginTop: "10px",textDecoration:"line-through",color:"black",height:"20px"  }}></div>
                    <div style={{ fontSize: "24px", fontWeight: "bold" }}>29$</div>
                    <div style={{ fontSize: "24px", fontWeight: "bold", marginTop: "10px" }}><a href={"https://weepay.link/Gb9Qq2"} rel="noopener noreferrer" style={{ backgroundColor: "#16CDB7", textDecoration: "none", color: "white", padding: 10, borderRadius: 10 }}>Buy</a></div>
                  </div>
                </div>
                <div className='col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3' style={{ marginTop: "10px" }}>
                  <div style={{ border: "1px solid #d9d9d9", padding: "10px", borderRadius: 10, paddingBottom: "30px", textAlign: "center" }}>
                  <div style={{ fontSize: "16px", color: "white" }}>-</div>

                    <div style={{ fontSize: "24px", fontWeight: "bold" }}>5000 COIN</div>
                    <div style={{ fontSize: "16px" }}><i style={{ color: "green" }} class="ri-check-line"></i>Unlimited Business</div>
                    <div style={{ fontSize: "16px" }}><i style={{ color: "green" }} class="ri-check-line"></i>Unlimited Staff</div>
                    <div style={{ fontSize: "16px" }}><i style={{ color: "green" }} class="ri-check-line"></i>Unlimited Appointments</div>
                    <div style={{ fontSize: "16px" }}><i style={{ color: "green" }} class="ri-check-line"></i>5000 Sending Email</div>
                    <div style={{ fontSize: "16px" }}><i style={{ color: "green" }} class="ri-check-line"></i>5000 Sending Whatsapp Message</div>
                    <div style={{ fontSize: "16px" }}><i style={{ color: "green" }} class="ri-check-line"></i>Automatic Messages</div>
                    <div style={{ fontSize: "18px", fontWeight: "bold", marginTop: "10px",textDecoration:"line-through",color:"black",height:"20px"  }}></div>
                    <div style={{ fontSize: "24px", fontWeight: "bold"}}>139 $</div>

                    <div style={{ fontSize: "24px", fontWeight: "bold", marginTop: "10px" }}><a href={"https://weepay.link/Gb9Qqf"} rel="noopener noreferrer" style={{ backgroundColor: "#16CDB7", textDecoration: "none", color: "white", padding: 10, borderRadius: 10 }}>Buy</a></div>
                  </div>
                </div>
                <div className='col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3' style={{ marginTop: "10px" }}>
                  <div style={{ border: "1px solid #d9d9d9", padding: "10px", borderRadius: 10, paddingBottom: "30px", textAlign: "center" }}>
                  <div style={{ fontSize: "16px", color: "white" }}>-</div>

                    <div style={{ fontSize: "24px", fontWeight: "bold" }}>10000 COIN</div>
                    <div style={{ fontSize: "16px" }}><i style={{ color: "green" }} class="ri-check-line"></i>Unlimited Business</div>
                    <div style={{ fontSize: "16px" }}><i style={{ color: "green" }} class="ri-check-line"></i>Unlimited Staff</div>
                    <div style={{ fontSize: "16px" }}><i style={{ color: "green" }} class="ri-check-line"></i>Unlimited Appointments</div>
                    <div style={{ fontSize: "16px" }}><i style={{ color: "green" }} class="ri-check-line"></i>10000 Sending Email</div>
                    <div style={{ fontSize: "16px" }}><i style={{ color: "green" }} class="ri-check-line"></i>10000 Sending Whatsapp Message</div>
                    <div style={{ fontSize: "16px" }}><i style={{ color: "green" }} class="ri-check-line"></i>Automatic Messages</div>
                    <div style={{ fontSize: "18px", fontWeight: "bold", marginTop: "10px",textDecoration:"line-through",color:"black",height:"20px"  }}></div>
                    <div style={{ fontSize: "24px", fontWeight: "bold"}}>249 $</div>

                    <div style={{ fontSize: "24px", fontWeight: "bold", marginTop: "10px" }}><a href={"https://weepay.link/Gb9I1H"} rel="noopener noreferrer" style={{ backgroundColor: "#16CDB7", textDecoration: "none", color: "white", padding: 10, borderRadius: 10 }}>Buy</a></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className='container-fluid' style={{ marginTop: "100px" }}>
        <div className='row justify-content-center'>
          <div className='col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8'>
            <div className='container-fluid'>
              <div className='row justify-content-center'>
                <div className='col-12 col-sm-12 col-md-10 col-lg-6 col-xl-6'>

                  <div style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div>
                      <h3 style={{ fontSize: "34px" }}>How it works?</h3>
                      <p style={{ fontSize: "18px", color: "#4f4f4f" }}>
                        You can learn how the application works by watching the video below.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-sm-12 col-md-10 col-lg-6 col-xl-6' style={{ marginTop: "100px" }}>
                  <div style={{ height: "600px", textAlign: "center" }}>
                    <iframe
                      style={{ borderRadius: "30px", border: "1px solid #aaa" }}
                      src="https://drive.google.com/file/d/1xaQIVLNigSx4RRUD9Yv3sg3dcZuyU1I8/preview"
                      width="100%"
                      height="480"
                      allow="autoplay"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='container-fluid' style={{ marginTop: "20px" }}>
        <div className='row justify-content-center'>
          <div className='col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8'>
            <div className='container-fluid'>
              <div className='row justify-content-center'>
                <div className='col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8'>

                  <div style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div>
                      <h3 style={{ fontSize: "34px" }}>Our application will be available on both Google Play store and App store very soon!</h3>
                      <p style={{ fontSize: "18px", color: "#4f4f4f" }}>
                      We offer you the experience we provide in the web interface in our application. You can download the application and continue making appointments, sending WhatsApp messages and e-mails.
                      </p>
                      <div style={{ textAlign: "start", marginTop: "100px" }}>

                        <img src='google.png' style={{ width: "200px" }} />


                        <img src='apple.png' style={{ width: "200px" }} />

                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-sm-12 col-md-10 col-lg-4 col-xl-4' style={{ marginTop: "100px" }}>
                  <div style={{ height: "600px", textAlign: "center" }}>
                    <img src='phone.png' style={{ height: "100%" }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>




      <div className='container-fluid' style={{ marginTop: "50px", marginBottom: "50px" }}>
        <div className='row justify-content-center'>
          <div className='col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8'>
            <div className='container-fluid'>
              <h2 style={{ textAlign: "start", fontSize: "34px", marginBottom: "40px" }}>Why Randevu Defterim?</h2>
              <div className='row justify-content-center'>
                <div className='col-12 col-sm-12 col-md-10 col-lg-3 col-xl-3' style={{ marginTop: "10px" }}>
                  <div style={{ border: "1px solid #d9d9d9", height: "160px", borderRadius: "10px", padding: "10px" }}>
                    <div style={{ textAlign: "start" }}><i style={{ fontSize: "32px", backgroundColor: "#50aff2", color: "white", borderRadius: "10px", padding: "5px" }} class="ri-money-dollar-circle-line"></i></div>
                    <h3 style={{ fontSize: "18px", textAlign: "start", fontWeight: "bold" }}>Free</h3>
                    <p style={{ fontSize: "14px", textAlign: "start", fontWeight: "normal" }}>We know you're tired of paying heaps of money. That's why you can now keep track of your appointments for free.</p>
                  </div>
                </div>
                <div className='col-12 col-sm-12 col-md-10 col-lg-3 col-xl-3' style={{ marginTop: "10px" }}>
                  <div style={{ border: "1px solid #d9d9d9", height: "160px", borderRadius: "10px", padding: "10px" }}>
                    <div style={{ textAlign: "start" }}><i style={{ fontSize: "32px", backgroundColor: "#ed894e", color: "white", borderRadius: "10px", padding: "5px" }} class="ri-customer-service-2-line"></i></div>
                    <h3 style={{ fontSize: "18px", textAlign: "start", fontWeight: "bold" }}>Unlimited businesses</h3>
                    <p style={{ fontSize: "14px", textAlign: "start", fontWeight: "normal" }}>You can open an unlimited number of businesses and manage all your appointments from one place.</p>
                  </div>
                </div>
                <div className='col-12 col-sm-12 col-md-10 col-lg-3 col-xl-3' style={{ marginTop: "10px" }}>
                  <div style={{ border: "1px solid #d9d9d9", height: "160px", borderRadius: "10px", padding: "10px" }}>
                    <div style={{ textAlign: "start" }}><i style={{ fontSize: "32px", backgroundColor: "#16CDB7", color: "white", borderRadius: "10px", padding: "5px" }} class="ri-user-2-line"></i></div>
                    <h3 style={{ fontSize: "18px", textAlign: "start", fontWeight: "bold" }}>Unlimited Employees</h3>
                    <p style={{ fontSize: "14px", textAlign: "start", fontWeight: "normal" }}>By adding multiple employees to your business, you can manage their appointments from one place.</p>
                  </div>
                </div>
                <div className='col-12 col-sm-12 col-md-10 col-lg-3 col-xl-3' style={{ marginTop: "10px" }}>
                  <div style={{ border: "1px solid #d9d9d9", height: "160px", borderRadius: "10px", padding: "10px" }}>
                    <div style={{ textAlign: "start" }}><i style={{ fontSize: "32px", backgroundColor: "#c24df0", color: "white", borderRadius: "10px", padding: "5px" }} class="ri-mail-line"></i></div>
                    <h3 style={{ fontSize: "18px", textAlign: "start", fontWeight: "bold" }}>Unlimited Email</h3>
                    <p style={{ fontSize: "14px", textAlign: "start", fontWeight: "normal" }}>You can send as many e-mails as you want to your customers on special days.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='container-fluid' style={{ marginTop: "50px", marginBottom: "50px", backgroundColor: "white" }}>
        <div className='row justify-content-center'>
          <div className='col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8'>
            <div style={{ height: "700px", display: "flex", alignItems: "center", justifyContent: "center" }}>
              <div style={{ textAlign: "center" }}>
                <h3 style={{ fontSize: "34px", color: "#05036B", textAlign: "center" }}>Track Your Appointments Free </h3>
                <p style={{ fontSize: "18px", color: "black", textAlign: "center" }}>İşletmeni açtıktan sonra kendini de çalışan olarak ekleyebilir sonrasında randevu vermeye başlayabilirsin. Randevularından önce müşterilerine e-postalar yollayabilir. Ücretsiz planlarını gerçekleştirmenin tadını çıkarabilirsin.</p>
                <a href={"https://www.randevudefterim.com/register"} target='_blank' style={{ backgroundColor: "#f5914e", color: "white", textDecoration: "none", padding: "10px", borderRadius: "10px" }}>Register</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='container-fluid' style={{ backgroundColor: "white", marginTop: "50px", height: "auto", marginBottom: "50px" }}>
        <div className='row justify-content-center'>
          <div className='col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8' style={{ paddingTop: "100px" }}>
            <div className='container'>
              <div className='row'>
                <div className='col-12 col-sm-12 col-md-10 col-lg-4 col-xl-4'>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "start", flexDirection: "column" }}>
                    <a style={{ textDecoration: "none", color: "black", fontSize: "44px", fontWeight: "bold", display: "block", width: "100%" }} href={"https://www.randevudefterim.com/register"} target='_blank'>Register</a>
                    <div style={{ textAlign: "start" }}>

                      <img src='google.png' style={{ width: "200px" }} />


                      <img src='apple.png' style={{ width: "200px" }} />

                    </div>
                  </div>
                </div>
                <div className='col-12 col-sm-12 col-md-10 col-lg-4 col-xl-4' style={{ marginTop: "50px" }}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "start", textAlign: "start" }}>
                    <div style={{}}>
                      <Link style={{ textDecoration: "none", color: "black", fontSize: "16px", display: "block" }} to="/blog">Blog</Link>
                      <Link style={{ textDecoration: "none", color: "black", fontSize: "16px", display: "block" }} to="/randevu-takip-programi">Randevu Takip Programı</Link>
                      <Link style={{ textDecoration: "none", color: "black", fontSize: "16px", display: "block" }} to="/dis-hekimi-randevu-programi">Diş Hekimi Randevu Programı</Link>
                      <Link style={{ textDecoration: "none", color: "black", fontSize: "16px", display: "block" }} to="/guzellik-salonu-randevu-programi">Güzellik Salonu Randevu Programı</Link>
                      <Link style={{ textDecoration: "none", color: "black", fontSize: "16px", display: "block" }} to="/diyetisyen-randevu-programi">Diyetisyen Randevu Programı</Link>
                      <Link style={{ textDecoration: "none", color: "black", fontSize: "16px", display: "block" }} to="/berber-randevu-programi">Berber Randevu Programı</Link>
                      <Link style={{ textDecoration: "none", color: "black", fontSize: "16px", display: "block" }} to="/guzellik-merkezi-randevu-programi">Güzellik Merkezi Randevu Programı</Link>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-sm-12 col-md-10 col-lg-4 col-xl-4' style={{ marginTop: "50px" }}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", width: "100%" }}>
                    <p style={{ fontSize: "14px", textAlign: "start", width: "100%" }}>Cevizlidere Ankara/TÜRKİYE</p>
                    <p style={{ fontSize: "14px", textAlign: "start", width: "100%" }}><i style={{ fontSize: "18px", marginRight: "10px" }} class="ri-customer-service-line"></i>+90 (0549) 550 05 09</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;

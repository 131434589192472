
import { Helmet } from 'react-helmet-async';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import MainMenu from '../../../Components/MainMenu';

function BerberRandevuProgramı() {

    return (
        <div style={{ height: "auto", overflow: "auto" }}>
            <Helmet>
                <title>Berber Randevu Programı</title>
                <meta name='description' content='Berber dükkanlarında müşteri randevularını yönetmek ve takip etmek önemlidir. İşte tam bu noktada devreye giren Randevu Defterim uygulaması berber randevu programıdır ve berberlerin tüm randevu ihtiyaçlarını karşılayacak mükemmel bir çözüm sunuyor.' />
                <meta property="og:title" content="Berber Randevu Programı"></meta>
                <meta property="og:description" content="Berber dükkanlarında müşteri randevularını yönetmek ve takip etmek önemlidir. İşte tam bu noktada devreye giren Randevu Defterim uygulaması berber randevu programıdır ve berberlerin tüm randevu ihtiyaçlarını karşılayacak mükemmel bir çözüm sunuyor."></meta>
                <meta property="og:image" content="https://www.randevudefterim.com/icon.png"></meta>
                <meta property="og:url" content="https://www.randevudefterim.com/berber-randevu-programi"></meta>
                <meta name="twitter:title" content="Berber Randevu Programı"></meta>
                <meta name="twitter:description" content="Berber dükkanlarında müşteri randevularını yönetmek ve takip etmek önemlidir. İşte tam bu noktada devreye giren Randevu Defterim uygulaması berber randevu programıdır ve berberlerin tüm randevu ihtiyaçlarını karşılayacak mükemmel bir çözüm sunuyor."></meta>
                <meta name="twitter:url" content="https://www.randevudefterim.com/icon.png"></meta>
                <meta name="keywords" content="berber randevu programı, berber yazılımı, berber randevu yönetimi, online berber randevusu, berber salonu randevusu, müşteri randevu takibi, berber uygulaması, randevu defterim"/>
            </Helmet>
            <MainMenu type={2} />
            <div className='container-fluid' style={{ marginTop: "100px", marginBottom: "100px" }}>
                <div className='row justify-content-center'>
                    <div className='col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8'>
                        <h1>Berber Randevu Programı</h1>
                        <p>Berber dükkanlarında müşteri randevularını yönetmek ve takip etmek önemlidir. İşte tam bu noktada devreye giren Randevu Defterim uygulaması, berberlerin tüm randevu ihtiyaçlarını karşılayacak mükemmel bir çözüm sunuyor.</p>

                        <h2>Randevu Defterim Nedir?</h2>
                        <p>Randevu Defterim, berberlerin kolayca kullanabileceği kapsamlı bir randevu takip programıdır. Bu uygulama sayesinde, berberler hızlıca randevu oluşturabilir, müşterilerini kaydedebilir ve ücretsiz hatırlatma mesajları gönderebilir. Üstelik, tüm bu hizmetler tamamen ücretsizdir.</p>

                        <h2>Randevu Defterim'in Özellikleri</h2>
                        <ul>
                            <li><strong>Sınırsız Randevu Ekleme:</strong> Dükkanınızın kapasitesi ne olursa olsun, sınırsız sayıda randevu ekleyebilir ve yönetebilirsiniz.</li>
                            <li><strong>Müşteri Yönetimi:</strong> Müşterilerinizin bilgilerini kaydedebilir, geçmiş randevularını takip edebilir ve gelecekteki randevuları kolayca planlayabilirsiniz.</li>
                            <li><strong>Ücretsiz Hatırlatma Mesajları:</strong> Müşterilerinize randevu hatırlatmaları ve bilgilendirme mesajları gönderebilir, müşteri memnuniyetini artırabilirsiniz.</li>
                            <li><strong>Kullanıcı Dostu Arayüz:</strong> Basit ve kullanıcı dostu arayüzü sayesinde, uygulamayı kolayca kullanabilir ve randevularınızı hızlıca yönetebilirsiniz.</li>
                        </ul>

                        <h2>Neden Randevu Defterim?</h2>
                        <p>Randevu Defterim, berber dükkanınızın operasyonel verimliliğini artırırken müşteri memnuniyetinizi en üst seviyeye çıkarmak için tasarlanmıştır. Ücretsiz olması, sınırsız randevu ekleme imkanı sunması gibi avantajlarıyla rakiplerinden ayrılmaktadır. Dükkanınız için en uygun randevu takip programı olan Randevu Defterim’i kullanarak zamanınızı daha verimli kullanabilir ve müşterilerinize daha iyi hizmet verebilirsiniz.</p>

                        <p>Daha fazla bilgi almak ve hemen kullanmaya başlamak için <a href="https://www.randevudefterim.com">Randevu Defterim</a> web sitesini ziyaret edebilirsiniz.</p>

                        <p>Randevu yönetiminde devrim yaratacak bu uygulamayı denemek için ne bekliyorsunuz? Berber dükkanınızın ihtiyaç duyduğu tüm özellikleri bir arada sunan Randevu Defterim ile tanışın ve müşteri memnuniyetinizi artırın!</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BerberRandevuProgramı;

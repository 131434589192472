
import { Helmet } from 'react-helmet-async';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import MainMenu from '../../../Components/MainMenu';

function Blog() {

    return (
        <div style={{ height: "auto", overflow: "auto" }}>
            <Helmet>
                <title>Blog | Randevu Defterim</title>
                <meta name='description' content='Türkiyenin Ücretsiz Randevu takip Programı Randevu defterim ile randevularınızı ücretsiz bir şekilde oluşturabilir. Sınırsız personel ekleyebilir. Müşterilerinize e-posta yollayabilirsiniz.' />
                <meta property="og:title" content="Randevu Takip Programı"></meta>
                <meta property="og:description" content="Türkiyenin Ücretsiz Randevu takip sistemi Randevu defterim ile randevularınızı ücretsiz bir şekilde oluşturabilir. Sınırsız personel ekleyebilir. Müşterilerinize e-posta yollayabilirsiniz."></meta>
                <meta property="og:image" content="https://www.randevudefterim.com/icon.png"></meta>
                <meta property="og:url" content="https://www.randevudefterim.com/randevu-takip-programi"></meta>
                <meta name="twitter:title" content="Randevu Takip Programı"></meta>
                <meta name="twitter:description" content="Türkiyenin Ücretsiz Randevu takip Programı Randevu defterim ile randevularınızı ücretsiz bir şekilde oluşturabilir. Sınırsız personel ekleyebilir. Müşterilerinize e-posta yollayabilirsiniz."></meta>
                <meta name="twitter:url" content="https://www.randevudefterim.com/icon.png"></meta>
                <meta name="keywords" content="randevu takip programı, randevu yönetimi, online randevu, randevu uygulaması, randevu takibi, randevu planlama, randevu yazılımı, müşteri randevusu, işletme randevusu, randevu defterim" />
            </Helmet>
            <MainMenu type={2} />
            <div className='container-fluid' style={{ marginTop: "100px", marginBottom: "100px" }}>
                <div className='row justify-content-center'>
                    <div className='col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8'>
                        <h1>Blog | Randevu Defterim</h1>
                        <div style={{ border: "1px solid black", height: "40px", display: "flex", alignItems: "center", justifyContent: "start", padding: "10px", marginBottom: "10px" }}>
                            <Link style={{ textDecoration: "none", color: "black", fontSize: "16px", display: "block" }} to="/randevu-takip-programi">Randevu Takip Programı</Link>
                        </div>
                        <div style={{ border: "1px solid black", height: "40px", display: "flex", alignItems: "center", justifyContent: "start", padding: "10px", marginBottom: "10px" }}>
                            <Link style={{ textDecoration: "none", color: "black", fontSize: "16px", display: "block" }} to="/dis-hekimi-randevu-programi">Diş Hekimi Randevu Programı</Link>
                        </div>
                        <div style={{ border: "1px solid black", height: "40px", display: "flex", alignItems: "center", justifyContent: "start", padding: "10px", marginBottom: "10px" }}>
                            <Link style={{ textDecoration: "none", color: "black", fontSize: "16px", display: "block" }} to="/guzellik-salonu-randevu-programi">Güzellik Salonu Randevu Programı</Link>
                        </div>
                        <div style={{ border: "1px solid black", height: "40px", display: "flex", alignItems: "center", justifyContent: "start", padding: "10px", marginBottom: "10px" }}>
                            <Link style={{ textDecoration: "none", color: "black", fontSize: "16px", display: "block" }} to="/diyetisyen-randevu-programi">Diyetisyen Randevu Programı</Link>
                        </div>
                        <div style={{ border: "1px solid black", height: "40px", display: "flex", alignItems: "center", justifyContent: "start", padding: "10px", marginBottom: "10px" }}>
                            <Link style={{ textDecoration: "none", color: "black", fontSize: "16px", display: "block" }} to="/berber-randevu-programi">Berber Randevu Programı</Link>
                        </div>
                        <div style={{ border: "1px solid black", height: "40px", display: "flex", alignItems: "center", justifyContent: "start", padding: "10px", marginBottom: "10px" }}>
                            <Link style={{ textDecoration: "none", color: "black", fontSize: "16px", display: "block" }} to="/guzellik-merkezi-randevu-programi">Güzellik Merkezi Randevu Programı</Link>
                        </div>
                        <div style={{ border: "1px solid black", height: "40px", display: "flex", alignItems: "center", justifyContent: "start", padding: "10px", marginBottom: "10px" }}>
                            <Link style={{ textDecoration: "none", color: "black", fontSize: "16px", display: "block" }} to="/randevu-programi">Randevu Programı</Link>
                        </div>
                        <div style={{ border: "1px solid black", height: "40px", display: "flex", alignItems: "center", justifyContent: "start", padding: "10px", marginBottom: "10px" }}>
                            <Link style={{ textDecoration: "none", color: "black", fontSize: "16px", display: "block" }} to="/online-randevu-programi">Online Randevu Programı</Link>
                        </div>
                        <div style={{ border: "1px solid black", height: "40px", display: "flex", alignItems: "center", justifyContent: "start", padding: "10px", marginBottom: "10px" }}>
                            <Link style={{ textDecoration: "none", color: "black", fontSize: "16px", display: "block" }} to="/nail-art-kurslari">Nail Art Kursları</Link>
                        </div>
                        <div style={{ border: "1px solid black", height: "40px", display: "flex", alignItems: "center", justifyContent: "start", padding: "10px", marginBottom: "10px" }}>
                            <Link style={{ textDecoration: "none", color: "black", fontSize: "16px", display: "block" }} to="/nail-art-ekipmanlari">Nail Art Ekipmanları</Link>
                        </div>
                        <div style={{ border: "1px solid black", height: "40px", display: "flex", alignItems: "center", justifyContent: "start", padding: "10px", marginBottom: "10px" }}>
                            <Link style={{ textDecoration: "none", color: "black", fontSize: "16px", display: "block" }} to="/randevu-defteri">Randevu Defteri</Link>
                        </div>
                        <div style={{ border: "1px solid black", height: "40px", display: "flex", alignItems: "center", justifyContent: "start", padding: "10px", marginBottom: "10px" }}>
                            <Link style={{ textDecoration: "none", color: "black", fontSize: "16px", display: "block" }} to="/dovme-malzemeleri">Dövme Malzemeleri</Link>
                        </div>
                        <div style={{ border: "1px solid black", height: "40px", display: "flex", alignItems: "center", justifyContent: "start", padding: "10px", marginBottom: "10px" }}>
                            <Link style={{ textDecoration: "none", color: "black", fontSize: "16px", display: "block" }} to="/beslenme-makaleleri">Beslenme Makaleleri</Link>
                        </div>
                        <div style={{ border: "1px solid black", height: "40px", display: "flex", alignItems: "center", justifyContent: "start", padding: "10px", marginBottom: "10px" }}>
                            <Link style={{ textDecoration: "none", color: "black", fontSize: "16px", display: "block" }} to="/beslenme-diyetetik-dergileri">Beslenme Diyetetik Dergileri</Link>
                        </div>
                        <div style={{ border: "1px solid black", height: "40px", display: "flex", alignItems: "center", justifyContent: "start", padding: "10px", marginBottom: "10px" }}>
                            <Link style={{ textDecoration: "none", color: "black", fontSize: "16px", display: "block" }} to="/randevu-yonetimi">Randevu Yönetimi</Link>
                        </div>
                        <div style={{ border: "1px solid black", height: "40px", display: "flex", alignItems: "center", justifyContent: "start", padding: "10px", marginBottom: "10px" }}>
                            <Link style={{ textDecoration: "none", color: "black", fontSize: "16px", display: "block" }} to="/hasta-memnuniyeti">Hasta Memuniyetini Etkileyen Faktörler</Link>
                        </div>
                        <div style={{ border: "1px solid black", height: "40px", display: "flex", alignItems: "center", justifyContent: "start", padding: "10px", marginBottom: "10px" }}>
                            <Link style={{ textDecoration: "none", color: "black", fontSize: "16px", display: "block" }} to="/guzellik-merkezi-cihazlar">Güzellik Merkezi İçin Gerekli Cihazlar</Link>
                        </div>
                        <div style={{ border: "1px solid black", height: "40px", display: "flex", alignItems: "center", justifyContent: "start", padding: "10px", marginBottom: "10px" }}>
                            <Link style={{ textDecoration: "none", color: "black", fontSize: "16px", display: "block" }} to="/hizmet-kalitesini-artirma">Hizmet Kalitesini Artırma Yolları</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Blog;


import { Helmet } from 'react-helmet-async';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import MainMenu from '../../../Components/MainMenu';

function RandevuTakipProgramı() {

    return (
        <div style={{ height: "auto", overflow: "auto" }}>
            <Helmet>
                <title>Randevu Takip Programı</title>
                <meta name='description' content='Türkiyenin Ücretsiz Randevu takip Programı Randevu defterim ile randevularınızı ücretsiz bir şekilde oluşturabilir. Sınırsız personel ekleyebilir. Müşterilerinize e-posta yollayabilirsiniz.' />
                <meta property="og:title" content="Randevu Takip Programı"></meta>
                <meta property="og:description" content="Türkiyenin Ücretsiz Randevu takip sistemi Randevu defterim ile randevularınızı ücretsiz bir şekilde oluşturabilir. Sınırsız personel ekleyebilir. Müşterilerinize e-posta yollayabilirsiniz."></meta>
                <meta property="og:image" content="https://www.randevudefterim.com/icon.png"></meta>
                <meta property="og:url" content="https://www.randevudefterim.com/randevu-takip-programi"></meta>
                <meta name="twitter:title" content="Randevu Takip Programı"></meta>
                <meta name="twitter:description" content="Türkiyenin Ücretsiz Randevu takip Programı Randevu defterim ile randevularınızı ücretsiz bir şekilde oluşturabilir. Sınırsız personel ekleyebilir. Müşterilerinize e-posta yollayabilirsiniz."></meta>
                <meta name="twitter:url" content="https://www.randevudefterim.com/icon.png"></meta>
                <meta name="keywords" content="randevu takip programı, randevu yönetimi, online randevu, randevu uygulaması, randevu takibi, randevu planlama, randevu yazılımı, müşteri randevusu, işletme randevusu, randevu defterim"/>

            </Helmet>
            <MainMenu type={2} />
            <div className='container-fluid' style={{ marginTop: "100px", marginBottom: "100px" }}>
                <div className='row justify-content-center'>
                    <div className='col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8'>
                        <h1>Randevu Takip Programı</h1>
                        <p>Günümüzde işletmelerin müşteri randevularını yönetmek ve takip etmek için etkili bir sisteme ihtiyaçları var. İşte tam bu noktada devreye giren Randevu Defterim uygulaması, işletmenizin tüm randevu ihtiyaçlarını karşılayacak mükemmel bir çözüm sunuyor.</p>

                        <h2>Randevu Defterim Nedir?</h2>
                        <p>Randevu Defterim, randevu veren tüm işletmelerin kullanabileceği kapsamlı bir randevu takip programıdır. Bu uygulama sayesinde, işletmeler kolayca randevu verebilir, müşterilerini kaydedebilir ve ücretsiz e-postalar gönderebilir. Üstelik, tüm bu hizmetler tamamen ücretsizdir.</p>

                        <h2>Randevu Defterim'in Özellikleri</h2>
                        <ul>
                            <li><strong>Sınırsız Randevu Ekleme:</strong> İşletmenizin büyüklüğü ne olursa olsun, sınırsız sayıda randevu ekleyebilir ve yönetebilirsiniz.</li>
                            <li><strong>Sınırsız Personel Ekleme:</strong> İşletmenizin tüm personelini sisteme ekleyebilir ve her biri için ayrı randevu takvimi oluşturabilirsiniz.</li>
                            <li><strong>Müşteri Yönetimi:</strong> Müşterilerinizin bilgilerini kaydedebilir, geçmiş randevularını takip edebilir ve gelecekteki randevuları kolayca yönetebilirsiniz.</li>
                            <li><strong>Ücretsiz E-posta Gönderme:</strong> Müşterilerinize hatırlatma e-postaları ve bilgilendirme mesajları gönderebilir, müşteri memnuniyetini artırabilirsiniz.</li>
                            <li><strong>Kullanıcı Dostu Arayüz:</strong> Basit ve sezgisel arayüzü sayesinde, uygulamayı kolayca kullanabilir ve hızlı bir şekilde randevularınızı yönetebilirsiniz.</li>
                        </ul>

                        <h2>Neden Randevu Defterim?</h2>
                        <p>Randevu Defterim, işletmenizin operasyonel verimliliğini artırmak ve müşteri memnuniyetini en üst seviyeye çıkarmak için tasarlanmıştır. Ücretsiz olması, sınırsız randevu ve personel ekleme imkanı sunması gibi avantajlarıyla rakiplerinden ayrılmaktadır. İşletmeniz için en uygun randevu takip programı olan Randevu Defterim’i kullanarak zamanınızı daha verimli kullanabilir ve müşterilerinize daha iyi hizmet verebilirsiniz.</p>

                        <p>Daha fazla bilgi almak ve hemen kullanmaya başlamak için <a href="https://www.randevudefterim.com">Randevu Defterim</a> web sitesini ziyaret edebilirsiniz.</p>

                        <p>Randevu yönetiminde devrim yaratacak bu uygulamayı denemek için ne bekliyorsunuz? İşletmenizin ihtiyaç duyduğu tüm özellikleri bir arada sunan Randevu Defterim ile tanışın ve müşteri memnuniyetinizi artırın!</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RandevuTakipProgramı;

import axios from 'axios';

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: 'https://www.randevudefterim.com:3000',
  timeout: 10000,
});

// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // Add common headers, e.g., Authorization
    const token = localStorage.getItem("token");
    const lang = "en"
    if (token) {
      config.headers.Authorization = `${token}`;
    }

    if(lang){
      config.headers["Accept-Language"] = lang
    }

    return config;
  },
  (error) => {
    // Handle request error
    console.error('Request error:', error);
    return Promise.reject(error);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // Log response
    console.log('Response:', response);
    return response;
  },
  (error) => {
    // Handle response error
    if (error.response) {
      // Server responded with a status other than 2xx
      console.error('Response error:', error.response);
    } else if (error.request) {
      // No response received
      console.error('No response received:', error.request);
    } else {
      // Something else happened
      console.error('Error:', error.message);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;

import { Helmet } from 'react-helmet-async';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import MainMenu from '../../../Components/MainMenu';

function RandevuProgramı() {

    return (
        <div style={{ height: "auto", overflow: "auto" }}>
            <Helmet>
                <title>Randevu Programı</title>
                <meta name="description" content="Müşteri randevularını yönetmek ve takip etmek önemlidir. İşte tam bu noktada devreye giren Randevu Defterim uygulaması, tüm randevu ihtiyaçlarını karşılayacak mükemmel bir çözüm sunuyor."></meta>
                <meta property="og:title" content="Randevu Programı"></meta>
                <meta property="og:description" content="Müşteri randevularını yönetmek ve takip etmek önemlidir. İşte tam bu noktada devreye giren Randevu Defterim uygulaması, tüm randevu ihtiyaçlarını karşılayacak mükemmel bir çözüm sunuyor."></meta>
                <meta property="og:image" content="https://www.randevudefterim.com/icon.png"></meta>
                <meta property="og:url" content="https://www.randevudefterim.com/randevu-programi"></meta>
                <meta name="twitter:title" content="Randevu Programı"></meta>
                <meta name="twitter:description" content="Müşteri randevularını yönetmek ve takip etmek önemlidir. İşte tam bu noktada devreye giren Randevu Defterim uygulaması, tüm randevu ihtiyaçlarını karşılayacak mükemmel bir çözüm sunuyor."></meta>
                <meta name="twitter:url" content="https://www.randevudefterim.com/randevu-programi"></meta>
                <meta name="keywords" content="randevu programı, randevu yönetimi, online randevu, randevu takibi, müşteri randevusu, işletme randevusu, randevu uygulaması, randevu yazılımı, Randevu Defterim"></meta>
            </Helmet>
            <MainMenu type={2} />
            <div className='container-fluid' style={{ marginTop: "100px", marginBottom: "100px" }}>
                <div className='row justify-content-center'>
                    <div className='col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8'>
                        <h1>Randevu Programı</h1>

                        <p>İşletmenizde müşteri randevularını yönetmek ve takip etmek için etkili bir çözüm arıyorsanız, <a href="https://www.randevudefterim.com">Randevu Defterim</a> uygulaması tam size göre. Randevu Defterim, kullanıcı dostu arayüzü ve kapsamlı özellikleri ile işletmenizin tüm randevu ihtiyaçlarını karşılayacak mükemmel bir çözüm sunuyor.</p>
                        <p>Randevu Programı ile müşterilerinizin randevularını kolayca planlayabilir, takip edebilir ve yönetebilirsiniz. Online randevu sistemi sayesinde müşterileriniz randevularını istedikleri zaman, istedikleri yerden alabilirler. Bu sayede hem siz hem de müşterileriniz için zamandan tasarruf sağlanır.</p>
                        <p>Randevu Defterim'in sunduğu çeşitli özellikler arasında anlık bildirimler, randevu hatırlatmaları, müşteri yönetimi ve daha fazlası bulunur. Tüm bu özellikler, işletmenizin verimliliğini artırırken müşteri memnuniyetini de üst düzeye çıkarır.</p>
                        <h2>Randevu Defterim Nedir?</h2>
                        <p>Randevu Defterim, berberlerin kolayca kullanabileceği kapsamlı bir randevu takip programıdır. Bu uygulama sayesinde, berberler hızlıca randevu oluşturabilir, müşterilerini kaydedebilir ve ücretsiz hatırlatma mesajları gönderebilir. Üstelik, tüm bu hizmetler tamamen ücretsizdir.</p>

                        <h2>Randevu Defterim'in Özellikleri</h2>
                        <ul>
                            <li><strong>Sınırsız Randevu Ekleme:</strong> Dükkanınızın kapasitesi ne olursa olsun, sınırsız sayıda randevu ekleyebilir ve yönetebilirsiniz.</li>
                            <li><strong>Müşteri Yönetimi:</strong> Müşterilerinizin bilgilerini kaydedebilir, geçmiş randevularını takip edebilir ve gelecekteki randevuları kolayca planlayabilirsiniz.</li>
                            <li><strong>Ücretsiz Hatırlatma Mesajları:</strong> Müşterilerinize randevu hatırlatmaları ve bilgilendirme mesajları gönderebilir, müşteri memnuniyetini artırabilirsiniz.</li>
                            <li><strong>Kullanıcı Dostu Arayüz:</strong> Basit ve kullanıcı dostu arayüzü sayesinde, uygulamayı kolayca kullanabilir ve randevularınızı hızlıca yönetebilirsiniz.</li>
                        </ul>

                        <h2>Neden Randevu Defterim?</h2>
                        <p>Randevu Defterim, berber dükkanınızın operasyonel verimliliğini artırırken müşteri memnuniyetinizi en üst seviyeye çıkarmak için tasarlanmıştır. Ücretsiz olması, sınırsız randevu ekleme imkanı sunması gibi avantajlarıyla rakiplerinden ayrılmaktadır. Dükkanınız için en uygun randevu takip programı olan Randevu Defterim’i kullanarak zamanınızı daha verimli kullanabilir ve müşterilerinize daha iyi hizmet verebilirsiniz.</p>

                        <p>Daha fazla bilgi almak ve hemen kullanmaya başlamak için <a href="https://www.randevudefterim.com">Randevu Defterim</a> web sitesini ziyaret edebilirsiniz.</p>

                        <p>Randevu yönetiminde devrim yaratacak bu uygulamayı denemek için ne bekliyorsunuz? Berber dükkanınızın ihtiyaç duyduğu tüm özellikleri bir arada sunan Randevu Defterim ile tanışın ve müşteri memnuniyetinizi artırın!</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RandevuProgramı;


function NotFound() {

  return (
    <div className="App">
      Not found
    </div>
  );

}

export default NotFound;

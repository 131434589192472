
import { Helmet } from 'react-helmet-async';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import MainMenu from '../../../Components/MainMenu';

function DiyetisyenRandevuProgramı() {

    return (
        <div style={{ height: "auto", overflow: "auto" }}>
            <Helmet>
                <title>Diyetisyen Randevu Programı</title>
                <meta name='description' content='Diyetisyenlik pratiğinde hasta randevularını etkili bir şekilde yönetmek ve takip etmek çok önemlidir. İşte tam bu noktada devreye giren Randevu Defterim uygulaması diyetisyen randevu programıdır ve diyetisyenlerin tüm randevu ihtiyaçlarını karşılayacak harika bir çözüm sunuyor.' />
                <meta property="og:title" content="Diyetisyen Randevu Programı"></meta>
                <meta property="og:description" content="Diyetisyenlik pratiğinde hasta randevularını etkili bir şekilde yönetmek ve takip etmek çok önemlidir. İşte tam bu noktada devreye giren Randevu Defterim uygulaması diyetisyen randevu programıdır ve diyetisyenlerin tüm randevu ihtiyaçlarını karşılayacak harika bir çözüm sunuyor."></meta>
                <meta property="og:image" content="https://www.randevudefterim.com/icon.png"></meta>
                <meta property="og:url" content="https://www.randevudefterim.com/diyetisyen-randevu-programi"></meta>
                <meta name="twitter:title" content="Diyetisyen Randevu Programı"></meta>
                <meta name="twitter:description" content="Diyetisyenlik pratiğinde hasta randevularını etkili bir şekilde yönetmek ve takip etmek çok önemlidir. İşte tam bu noktada devreye giren Randevu Defterim uygulaması diyetisyen randevu programıdır ve diyetisyenlerin tüm randevu ihtiyaçlarını karşılayacak harika bir çözüm sunuyor."></meta>
                <meta name="twitter:url" content="https://www.randevudefterim.com/icon.png"></meta>
                <meta name="keywords" content="diyetisyen randevu programı, diyetisyen yazılımı, diyetisyen randevu yönetimi, online diyetisyen randevusu, diyetisyen uygulaması, müşteri randevu takibi, diyet randevusu, randevu defterim"/>

            </Helmet>
            <MainMenu type={2} />
            <div className='container-fluid' style={{ marginTop: "100px", marginBottom: "100px" }}>
                <div className='row justify-content-center'>
                    <div className='col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8'>
                        <h1>Diyetisyen Randevu Programı</h1>
                        <p>Diyetisyenlik pratiğinde hasta randevularını etkili bir şekilde yönetmek ve takip etmek çok önemlidir. İşte tam bu noktada devreye giren Randevu Defterim uygulaması, diyetisyenlerin tüm randevu ihtiyaçlarını karşılayacak harika bir çözüm sunuyor.</p>

                        <h2>Randevu Defterim Nedir?</h2>
                        <p>Randevu Defterim, diyetisyenlerin kolayca kullanabileceği kapsamlı bir randevu takip programıdır. Bu uygulama sayesinde, diyetisyenler hızlıca randevu oluşturabilir, danışanlarını kaydedebilir ve ücretsiz hatırlatma e-postaları gönderebilir. Üstelik, tüm bu hizmetler tamamen ücretsizdir.</p>

                        <h2>Randevu Defterim'in Özellikleri</h2>
                        <ul>
                            <li><strong>Sınırsız Randevu Ekleme:</strong> Kliniğinizin kapasitesi ne olursa olsun, sınırsız sayıda randevu ekleyebilir ve yönetebilirsiniz.</li>
                            <li><strong>Sınırsız Danışan Ekleme:</strong> Danışanlarınızın bilgilerini detaylı bir şekilde kaydedebilir, randevularını ve ilerleme notlarını takip edebilirsiniz.</li>
                            <li><strong>İlgi Alanı Takibi:</strong> Danışanların sağlık hedeflerini, alışkanlıklarını ve ilaç takviyelerini sisteme ekleyebilir, gelişimlerini analiz edebilirsiniz.</li>
                            <li><strong>Ücretsiz E-posta Gönderme:</strong> Danışanlarınıza randevu hatırlatmaları ve sağlık bilgileri içeren e-postalar gönderebilir, motivasyonlarını artırabilirsiniz.</li>
                            <li><strong>Kullanıcı Dostu Arayüz:</strong> Basit ve sezgisel arayüzü sayesinde, uygulamayı kolayca kullanabilir ve danışanlarınızın sağlıklı yaşam yolculuğunu destekleyebilirsiniz.</li>
                        </ul>

                        <h2>Neden Randevu Defterim?</h2>
                        <p>Randevu Defterim, diyetisyenlerin kliniğinin operasyonel verimliliğini artırmak ve danışan memnuniyetini en üst seviyeye çıkarmak için tasarlanmıştır. Ücretsiz olması, sınırsız randevu ve danışan ekleme imkanı sunması gibi avantajlarıyla rakiplerinden ayrılmaktadır. Kliniğiniz için en uygun randevu takip programı olan Randevu Defterim’i kullanarak zamanınızı daha verimli kullanabilir ve danışanlarınıza daha iyi hizmet verebilirsiniz.</p>

                        <p>Daha fazla bilgi almak ve hemen kullanmaya başlamak için <a href="https://www.randevudefterim.com">Randevu Defterim</a> web sitesini ziyaret edebilirsiniz.</p>

                        <p>Randevu yönetiminde devrim yaratacak bu uygulamayı denemek için ne bekliyorsunuz? Diyetisyen kliniğinizin ihtiyaç duyduğu tüm özellikleri bir arada sunan Randevu Defterim ile tanışın ve danışan memnuniyetinizi artırın!</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DiyetisyenRandevuProgramı;

import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import axiosInstance from '../../Axios';
import { toast } from "react-toastify";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
function Notifications() {
    const authenticated = useSelector(state => state.login)
    const [notifications, setNotifications] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const getNotifications = async () => {
        setIsLoading(true)
        try {

            const response = await axiosInstance.get('/users/' + authenticated.user.id + "/notifications");

            setNotifications(response.data.notifications)
            setIsLoading(false)

        } catch (error) {

            if (error.response != undefined) {
                setIsLoading(false)
                toast.error(error.response.data.error)
            }

            else {
                toast.error("We are experiencing a connection problem. Try again later.")
            }

        }

    }

    useEffect(() => {
        getNotifications()
    }, [])


    return (
        <div className="App">
            <div className="container-fluid">
                <div className="row justify-content-center" style={{ marginBottom: "10px" }}>
                    <div className='col-11 col-sm-11 col-md-10 col-lg-6 col-xl-6' style={{ padding: "0px", zIndex: "10" }}>
                        <div style={{ border: "1px solid #EAEAEA", backgroundColor: "white", height: "auto", borderRadius: "20px", padding: "20px", position: "relative" }}>
                            {
                                isLoading &&
                                <div>
                                    <h2 style={{ textAlign: "start", color: "#969696", fontSize: "14px", marginBottom: "20px" }}>Notifications</h2>
                                    <Skeleton count={20} />
                                </div>
                            }
                            {
                                notifications.length == 0 && !isLoading &&
                                <p>We couldn't find any notifications!</p>
                            }
                            {
                                notifications.length > 0 &&
                                <div>
                                    <h2 style={{ textAlign: "start", color: "#969696", fontSize: "14px", marginBottom: "20px" }}>Notifications</h2>
                                    {
                                        notifications.map((notification, index) => {
                                            return (
                                                <div style={{ marginBottom: "20px" }}>
                                                    <span style={{ textAlign: "start", display: "block", fontSize: "12px", color: "#969696" }}>{notification.date.split("T")[0]} {notification.date.split("T")[1].split(":")[0]}:{notification.date.split("T")[1].split(":")[1]}</span>
                                                    <div style={{ border: "1px solid #ccc", padding: "10px", borderRadius: "10px", display: "flex", alignItems: "center", justifyContent: "start" }}>
                                                        <i style={{ fontSize: "20px", marginRight: "10px", backgroundColor: "#16CDB7", color: "white", padding: "10px", borderRadius: "10px", display: "inline-block", width: "40px", height: "40px", display: "flex", alignItems: "center", justifyContent: "center" }} class="ri-notification-4-line"></i>
                                                        <p style={{ textAlign: "start", margin: "0px", fontSize: "14px" }}>{notification.message}</p>

                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Notifications;


import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import axiosInstance from '../../Axios';
import { Button, Chip, Switch, TextField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Line, Circle } from 'rc-progress';
import { isMobile } from 'react-device-detect';


function Personels({ selectedBusiness }) {

    const [isSendMailModal, setIsSendMailModal] = useState(false)
    const [messages, setMessages] = useState([]);
    const [selectedMessage, setSelectedMessage] = useState({
        id: -1,
        businessid: -1,
        message: "",
        title: ""
    })
    const [isUpdateCustomerModal, setIsUpdateCustomerModal] = useState(false)
    const [isSendMailLoading, setIsSendMailLoading] = useState(false)

    const [isSendWpModal, setIsSendWpModal] = useState(false)

    const [holidays, setHolidays] = useState([])
    const getMessages = async () => {

        try {
            const response = await axiosInstance.get('/businesses/' + selectedBusiness.id + "/messages");

            setMessages(response.data)


        } catch (error) {

            if (error.response != undefined)

                toast.error(error.response.data.error)
            else {
                toast.error("We are experiencing a connection problem. Try again later.")
            }

        }

    }

    useEffect(() => {
        getHolidays()
    }, [])

    const getHolidays = async () => {

        try {
            const response = await axiosInstance.get('https://www.googleapis.com/calendar/v3/calendars/tr.turkish%23holiday@group.v.calendar.google.com/events?key=AIzaSyBUoMwVG2CBw6J_L4aodEu0Hfb8BB0toT4');

            setHolidays(response.data)


        } catch (error) {

            if (error.response != undefined)

                toast.error(error.response.data.error)
            else {
                toast.error("We are experiencing a connection problem. Try again later.")
            }

        }

    }

    const sendMail = async () => {


        if (selectedMessage.id != -1) {
            setIsSendMailLoading(true)




            try {

                const response = await axiosInstance.post('/businesses/' + selectedBusiness.id + "/operations",
                    {
                        title: selectedMessage.title,
                        message: selectedMessage.message,
                        to: addRandevu.mail
                    }
                );


                setIsSendMailModal(false)

                setSelectedMessage({
                    id: -1,
                    businessid: -1,
                    message: "",
                    title: ""
                })

                toast.success("E-mail has been sent.")


            } catch (error) {

                if (error.response != undefined)
                    toast.error(error.response.data.error)
                else {
                    toast.error("We are experiencing a connection problem. Try again later.")
                }

            }

            setTimeout(() => {
                setIsSendMailLoading(false)
            }, 2000)


        }
        else {
            toast.error("Please select Message Template.")
        }
    }

    const sendWp = async () => {


        if (selectedMessage.id != -1) {
            setIsSendMailLoading(true)




            try {

                const response = await axiosInstance.post('/businesses/' + selectedBusiness.id + "/whatsapp",
                    {
                        title: selectedMessage.title,
                        message: selectedMessage.message,
                        to: addRandevu.phone
                    }
                );


                setIsSendWpModal(false)

                setSelectedMessage({
                    id: -1,
                    businessid: -1,
                    message: "",
                    title: ""
                })

                toast.success("Whatsapp message has been sent.")


            } catch (error) {

                if (error.response != undefined)
                    toast.error(error.response.data.error)
                else {
                    toast.error("We are experiencing a connection problem. Try again later.")
                }

            }

            setTimeout(() => {
                setIsSendMailLoading(false)
            }, 2000)


        }
        else {
            toast.error("Please select Message Template.")
        }
    }



    const [personelList, setPersonelList] = useState([])
    const [isAddPersonelModal, setIsAddPersonelModal] = useState(false)
    const [isAddLoading, setIsAddLoading] = useState(false)
    const [appointments, setAppointments] = useState([])
    const [personel, setPersonel] = useState({
        personelphone: "",
        duration: "",
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: true,
        sunday: true
    })
    const [pageIndex, setPageIndex] = useState(-1)
    const [selectedPersonel, setSelectedPersonel] = useState(-1)
    const [selectedPersonelId, setSelectedPersonelId] = useState(-1)
    const [isAddRandevuModal, setIsAddRandevuModal] = useState(false)
    const [servicesList, setServicesList] = useState([])
    const [isAppointmentAddLoading, setIsAppointmentAddLoading] = useState(false)
    const [isShowRandevuModal, setIsShowRandevuModal] = useState(false)
    const [randevu, setRandevu] = useState(undefined)
    const [addRandevu, setAddRandevu] = useState({
        starttime: "",
        endtime: "",
        date: "",
        servisid: -1,
        name: "",
        phone: "",
        mail: "",
        birthdate: null,
        userid: null,
        state: 1
    })
    const [years, setYears] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null)

    useEffect(() => {
        // Generate the list of years from 2024 to 2100
        const startYear = 2024;
        const endYear = 2100;
        const yearArray = [];
        for (let year = startYear; year <= endYear; year++) {
            yearArray.push(year);
        }
        setYears(yearArray);
    }, []);

    const [month, setMonth] = useState(new Date().getMonth())
    const [year, setYear] = useState(new Date().getFullYear())

    const [days, setDays] = useState([]);

    useEffect(() => {
        if (selectedPersonel != -1)
            setPageIndex(0)
    }, [selectedPersonel])

    useEffect(() => {
        const getDaysInMonth = (year, month) => {
            return new Date(year, month + 1, 0).getDate();
        };

        const daysInMonth = getDaysInMonth(year, month);
        const daysArray = Array.from({ length: daysInMonth }, (_, i) => i + 1);
        setDays(daysArray);
    }, [month, year]);

    const getDayName = (year, month, day) => {
        const date = new Date(year, month, day);
        const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        return dayNames[date.getDay()];
    };

    const getMonthName = (month) => {
        const dayNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        return dayNames[month];
    };

    const getPersonels = async () => {

        try {
            const response = await axiosInstance.get('/businesses/' + selectedBusiness.id + "/personels");

            setPersonelList(response.data)


        } catch (error) {

            if (error.response != undefined)

                toast.error(error.response.data.error)
            else {
                toast.error("We are experiencing a connection problem. Try again later.")
            }

        }

    }

    useEffect(() => {
        getPersonels()
        getMessages()
    }, [selectedBusiness])

    const getPersonelById = () => {

        let i;
        for (i = 0; i < personelList.length; i++) {
            if (personelList[i].id == selectedPersonel) {
                return personelList[i]
            }
        }
    }

    const isSuitable = (year, month, day) => {

        const selpersonel = () => {
            let i;
            for (i = 0; i < personelList.length; i++) {
                if (personelList[i].id == selectedPersonel) {
                    return personelList[i]
                }
            }
        }

        const mypersonel = selpersonel()

        if (getDayName(year, month, day) == "Monday" && mypersonel.monday == "1")
            return true;
        else if (getDayName(year, month, day) == "Tuesday" && mypersonel.tuesday == "1")
            return true;
        else if (getDayName(year, month, day) == "Wednesday" && mypersonel.wednesday == "1")
            return true;
        else if (getDayName(year, month, day) == "Thursday" && mypersonel.thursday == "1")
            return true;
        else if (getDayName(year, month, day) == "Friday" && mypersonel.friday == "1")
            return true;
        else if (getDayName(year, month, day) == "Saturday" && mypersonel.saturday == "1")
            return true;
        else if (getDayName(year, month, day) == "Sunday" && mypersonel.sunday == "1")
            return true;

        return false;
    }


    const savePersonel = async () => {

        setIsAddLoading(true)
        try {

            const response = await axiosInstance.post('/businesses/' + selectedBusiness.id + "/personels",
                personel
            );

            getPersonels()

            setIsAddLoading(false)

            toast.success("Staff Has Been Added Successfully.")


        } catch (error) {

            if (error.response != undefined)
                toast.error(error.response.data.error + " The number you use for registration must be a member of randevudefterim.com.")
            else {
                toast.error("We are experiencing a connection problem. Try again later.")
            }

        }

        setTimeout(() => {
            setIsAddLoading(false)
        }, 2000)


    }

    const saveAppointment = async () => {

        if (addRandevu.servisid != -1) {

            setIsAppointmentAddLoading(true)
            try {

                const response = await axiosInstance.post('/businesses/' + selectedBusiness.id + "/personels/" + selectedPersonelId + "/appointments",
                    addRandevu
                );

                getAppointments()

                setIsAppointmentAddLoading(false)

                toast.success("Appointment Added Successfully.")


            } catch (error) {

                if (error.response != undefined)
                    toast.error(error.response.data.error)
                else {
                    toast.error("We are experiencing a connection problem. Try again later.")
                }

            }

            setTimeout(() => {
                setIsAppointmentAddLoading(false)
            }, 2000)

        }
        else {
            toast.error("Please add services on the service page.")
        }

    }

    function formatTime(date) {
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    }

    function generateTimeSlots(start, end, duration) {
        const slots = [];
        const startTime = new Date(`1970-01-01T${start}:00`);
        const endTime = new Date(`1970-01-01T${end}:00`);

        while (startTime < endTime) {
            const endSlotTime = new Date(startTime.getTime() + duration * 60000);
            if (endSlotTime > endTime) break;

            slots.push(`${formatTime(startTime)} - ${formatTime(endSlotTime)}`);
            startTime.setTime(startTime.getTime() + duration * 60000);
        }

        return slots;
    }
    const [duration, setDuration] = useState(30);
    const timeSlots = generateTimeSlots('08:00', '18:00', getPersonelById() != undefined ? getPersonelById().duration : 30);

    const deletePersonel = async (id) => {

        try {

            const response = await axiosInstance.delete('/businesses/' + selectedBusiness.id + "/personels/" + id);

            getPersonels()

            toast.success("The personnel has been successfully deleted.")

        } catch (error) {

            if (error.response != undefined)
                toast.error(error.response.data.error)
            else {
                toast.error("We are experiencing a connection problem. Try again later.")
            }

        }

    }

    const getAppointments = async (id) => {

        try {

            const response = await axiosInstance.get('/businesses/' + selectedBusiness.id + "/personels/" + selectedPersonelId + "/appointments" + "?month=" + (month + 1) + "&year=" + year);

            setAppointments(response.data)


        } catch (error) {

            if (error.response != undefined)
                toast.error(error.response.data.error)
            else {
                toast.error("We are experiencing a connection problem. Try again later.")
            }

        }

    }

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    };

    const getServices = async () => {

        try {
            const response = await axiosInstance.get('/businesses/' + selectedBusiness.id + "/services");

            setServicesList(response.data)


        } catch (error) {

            if (error.response != undefined)

                toast.error(error.response.data.error)
            else {
                toast.error("We are experiencing a connection problem. Try again later.")
            }

        }

    }

    const calculateCapacity = (day, month, year, selectedper) => {

        var j;

        var appointmentCountList = []
        console.log(days.length)
        for (j = 0; j < days.length; j++) {
            appointmentCountList.push(0)
        }

        let duration = getPersonelById(selectedper).duration

        let seansCount = 9 * 60 / duration

        let mydate = new Date(year, month, day)
        let i;
        for (i = 0; i < appointments.length; i++) {

            var appointmentDate = new Date(appointments[i].date.split("T")[0]);

            // Extract year, month, and day from the dates
            var appointmentYear = appointmentDate.getFullYear();
            var appointmentMonth = appointmentDate.getMonth();
            var appointmentDay = appointmentDate.getDate();

            var myYear = mydate.getFullYear();
            var myMonth = mydate.getMonth();
            var myDay = mydate.getDate();

            // Compare only year, month, and day
            var isSameDate = appointmentYear === myYear && appointmentMonth === myMonth && appointmentDay === myDay;

            if (isSameDate) {

                appointmentCountList[day - 1]++;
            }
        }
        return (appointmentCountList[day - 1] * 100 / seansCount).toFixed(2)
    }


    useEffect(() => {
        getAppointments()
    }, [month, year, selectedPersonelId])


    const deleteAppointment = async (id) => {

        try {

            const response = await axiosInstance.delete('/businesses/' + selectedBusiness.id + "/personels/" + selectedPersonelId + "/appointments/" + id);

            getAppointments()

            toast.success("The appointment has been deleted successfully.")

        } catch (error) {

            if (error.response != undefined)
                toast.error(error.response.data.error)
            else {
                toast.error("We are experiencing a connection problem. Try again later.")
            }

        }

    }

    const getRandevu = (slot) => {

        var i;
        for (i = 0; i < appointments.length; i++) {
            if (new Date(appointments[i].date.split("T")[0]).setHours(0, 0, 0, 0) == selectedDate.setHours(0, 0, 0, 0)) {
                if ((appointments[i].starttime + " - " + appointments[i].endtime) == slot) {
                    return appointments[i]
                }
            }
        }

        return undefined;

    }


    const isDayHoliday = (date) => {

        var i;
        for (i = 0; i < holidays.items.length; i++) {
            if (new Date(holidays.items[i].start.date).setHours(0, 0, 0, 0) == date.setHours(0, 0, 0, 0))
                return holidays.items[i].summary
        }
        return "";
    }

    if (pageIndex == -1) {
        return (
            <div className="App">
                <div style={{ display: "flex", alignItems: "center", justifyContent: "end", padding: "20px" }}>
                    <Button variant="contained" style={{ border: "none", borderRadius: "10px", backgroundColor: "#16CDB7", color: "white", padding: "10px" }} onClick={() => setIsAddPersonelModal(true)}><i class="ri-add-line"></i>Add Employee</Button>
                </div>
                <div>
                    {
                        personelList.length == 0 &&
                        <div style={{ marginBottom: "40px" }}>
                            We Couldn't Find Any Personnel at Your Business!
                        </div>
                    }
                    {
                        personelList.length > 0 &&
                        <div style={{ overflow: "auto", padding: "20px" }}>
                            <table class="table" style={{ marginTop: "20px" }}>
                                <thead >
                                    <tr>
                                        <th scope="col" style={{ color: "#5c5c5c", fontSize: "12px", textAlign: "start", fontWeight: "normal" }}>Name Surname</th>
                                        <th scope="col" style={{ color: "#5c5c5c", fontSize: "12px", textAlign: "start", fontWeight: "normal" }}>Duration</th>
                                        <th scope="col" style={{ color: "#969696", fontSize: "14px" }}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        personelList.map((personel, index) => {
                                            return (
                                                <tr style={{ border: "1px solid white" }}>
                                                    <td style={{ textAlign: "start", fontSize: "14px" }}>{isMobile ? "" : <img src={personel.image != null ? personel.image : "default.png"} style={{ borderRadius: "40px", height: "40px", width: "40px", marginRight: "10px" }} />}<b>{personel.name}</b></td>
                                                    <td style={{ textAlign: "start", fontSize: "14px" }}><b style={{ height: "40px", display: "flex", alignItems: "center", fontWeight: "normal" }}>{personel.duration} Minutes</b></td>
                                                    <td style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                                                        <Button variant="contained" style={{ marginRight: "10px", border: "none", backgroundColor: "#16CDB7", padding: "10px", borderRadius: "10px", color: "white" }} onClick={() => { setSelectedPersonel(personel.id); setSelectedPersonelId(personel.personelid) }}> Appointments</Button>
                                                        <Button variant="contained" style={{ border: "none", borderRadius: "10px", backgroundColor: "#f06960", color: "white", padding: "10px" }}
                                                            onClick={() => {
                                                                deletePersonel(personel.personelid)
                                                            }}
                                                        ><i class="ri-delete-bin-line"></i></Button>
                                                    </td>

                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
                {
                    isAddPersonelModal &&
                    <div style={{ position: "absolute", top: "0px", left: "0px", width: "100%", height: "100%", zIndex: "1000000000000000000000", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <div style={{ backgroundColor: "black", width: "100%", height: "100%", opacity: "0.4", position: "absolute", top: "0px", left: "0px", zIndex: "1000000000000000" }} onClick={() => setIsAddPersonelModal(false)}></div>
                        <div style={{ width: "350px", height: "auto", zIndex: "1000000000000000000000", backgroundColor: "white", borderRadius: "20px", padding: "20px" }}>
                            <h2 style={{ textAlign: "start", color: "#969696", fontSize: "14px", marginBottom: "20px" }}>Add Staff</h2>

                            <div>
                                <span style={{fontSize:"12px",color:"#888"}}>You can add a member user with their phone number.</span>
                                <TextField id="outlined-basic" label="Phone Number" variant="outlined" placeholder='Phone Number' style={{ marginTop: "10px", width: "100%", height: "40px" }} value={personel.personelphone} onChange={(e) => { setPersonel({ ...personel, personelphone: e.target.value }) }} />
                                <FormControl fullWidth style={{ marginTop: "30px", width: "100%", height: "40px" }} >
                                    <InputLabel id="demo-simple-select-label">Session Duration</InputLabel>
                                    <Select
                                        value={personel.duration} onChange={(e) => { setPersonel({ ...personel, duration: e.target.value }) }}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Seans Süresi"
                                    >
                                        <MenuItem value={"15"}>15 Minutes</MenuItem>
                                        <MenuItem value={"30"}>30 Minute</MenuItem>
                                        <MenuItem value={"60"}>60 Minutes</MenuItem>
                                    </Select>
                                </FormControl>
                                
                                <h2 style={{ fontSize: "16px", marginTop: "40px", textAlign: "start" }}>Staff Working Status</h2>
                                <span style={{fontSize:"12px",color:"#888",display:"block",textAlign:"start"}}>Determine which days your employee working.</span>

                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "10px" }}>
                                    <p style={{ margin: "0px", marginRight: "15px", color: "#5a5961", fontSize: "14px", width: "130px",textAlign:"start"}}>Monday:</p>
                                    <Switch defaultChecked checked={personel.monday} onChange={(e) => { setPersonel({ ...personel, monday: e.target.checked }) }} />
                                </div>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "10px" }}>
                                    <p style={{ margin: "0px", marginRight: "15px", color: "#5a5961", fontSize: "14px", width: "130px",textAlign:"start" }}>Tuesday:</p>
                                    <Switch defaultChecked checked={personel.tuesday} onChange={(e) => { setPersonel({ ...personel, tuesday: e.target.checked }) }} />
                                </div>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "10px" }}>
                                    <p style={{ margin: "0px", marginRight: "15px", color: "#5a5961", fontSize: "14px", width: "130px",textAlign:"start" }}>Wednesday:</p>
                                    <Switch defaultChecked checked={personel.wednesday} onChange={(e) => { setPersonel({ ...personel, wednesday: e.target.checked }) }} />
                                </div>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "10px" }}>
                                    <p style={{ margin: "0px", marginRight: "15px", color: "#5a5961", fontSize: "14px", width: "130px",textAlign:"start" }}>Thursday:</p>
                                    <Switch defaultChecked checked={personel.thursday} onChange={(e) => { setPersonel({ ...personel, thursday: e.target.checked }) }} />
                                </div>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "10px" }}>
                                    <p style={{ margin: "0px", marginRight: "15px", color: "#5a5961", fontSize: "14px", width: "130px",textAlign:"start" }}>Friday:</p>
                                    <Switch defaultChecked checked={personel.friday} onChange={(e) => { setPersonel({ ...personel, friday: e.target.checked }) }} />
                                </div>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "10px" }}>
                                    <p style={{ margin: "0px", marginRight: "15px", color: "#5a5961", fontSize: "14px", width: "130px",textAlign:"start" }}>Saturday:</p>
                                    <Switch defaultChecked checked={personel.saturday} onChange={(e) => { setPersonel({ ...personel, saturday: e.target.checked }) }} />
                                </div>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "10px" }}>
                                    <p style={{ margin: "0px", marginRight: "15px", color: "#5a5961", fontSize: "14px", width: "130px",textAlign:"start" }}>Sunday:</p>
                                    <Switch defaultChecked checked={personel.sunday} onChange={(e) => { setPersonel({ ...personel, sunday: e.target.checked }) }} />
                                </div>

                            </div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "end", marginTop: "40px" }}>
                                <Button variant="contained" style={{ padding: "10px", borderRadius: "10px", backgroundColor: "#16CDB7", color: "white", border: "none" }} disabled={isAddLoading} onClick={() => savePersonel()} >
                                    {
                                        isAddLoading ? <div class="spinner-border text-light" role="status"></div> : "Save"
                                    }
                                </Button>
                            </div>
                        </div>
                    </div>
                }
            </div >
        );
    }
    else if (pageIndex == 0) {
        return (
            <div style={{ padding: "20px", overflow: "auto" }}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Button onClick={() => { setPageIndex(-1); setSelectedPersonel(-1) }} variant='contained' style={{ padding: "10px", borderRadius: "10px", backgroundColor: "#16CDB7", color: "white", border: "none" }}><i style={{ fontSize: "20px" }} class="ri-arrow-left-line"></i></Button>
                    <p style={{ margin: "0px", fontSize: "12px", color: "#969696" }}>This month you have {appointments.length} appointments</p>
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "end", marginTop: "20px" }}>
                    <FormControl style={{ width: "200px" }}>
                        <InputLabel id="demo-simple-select-label">Month</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={month}
                            label="Month"
                            onChange={(e) => setMonth(e.target.value)}
                        >
                            <MenuItem value={0}>January</MenuItem>
                            <MenuItem value={1}>February</MenuItem>
                            <MenuItem value={2}>March</MenuItem>
                            <MenuItem value={3}>April</MenuItem>
                            <MenuItem value={4}>May</MenuItem>
                            <MenuItem value={5}>June</MenuItem>
                            <MenuItem value={6}>July</MenuItem>
                            <MenuItem value={7}>August</MenuItem>
                            <MenuItem value={8}>September</MenuItem>
                            <MenuItem value={9}>October</MenuItem>
                            <MenuItem value={10}>November</MenuItem>
                            <MenuItem value={11}>December</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl style={{ width: "200px", marginLeft: "20px" }}>
                        <InputLabel id="demo-simple-select-label">Yıl</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={year}
                            label="Year"
                            onChange={(e) => setYear(e.target.value)}
                        >
                            {
                                years.map((year) => {
                                    return (
                                        <MenuItem value={year}>{year}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                </div>
                <div>
                    <div>
                        <table class="table" style={{ marginTop: "20px" }}>
                            <thead >
                                <tr>
                                    <th scope="col" style={{ color: "#5c5c5c", fontSize: "12px", textAlign: "start", fontWeight: "normal" }}>Day(Count)</th>
                                    <th scope="col" style={{ color: "#5c5c5c", fontSize: "12px", textAlign: "start", fontWeight: "normal" }}>Rate</th>
                                    <th scope="col" style={{ color: "#5c5c5c", fontSize: "12px", textAlign: "start", fontWeight: "normal" }}>Status</th>
                                    <th scope="col" style={{ color: "#969696", fontSize: "14px" }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {days.map(day => (
                                    <tr key={day}>
                                        <td style={{ textAlign: "start", fontSize: "10px" }}>{day} {getMonthName(month)} {getDayName(year, month, day)}</td>
                                        <td style={{ textAlign: "start", fontSize: "14px" }}> <Line percent={calculateCapacity(day, month, year, selectedPersonel)} strokeWidth={4} strokeColor="#16CDB7" style={{ width: isMobile ? "50px" : "150px", marginRight: "10px" }} />{calculateCapacity(day, month, year, selectedPersonel)}%</td>
                                        <td style={{ textAlign: "start" }}>{isDayHoliday(new Date(year, month, day)) != "" ? <Chip color="warning" label="Özel Gün"></Chip> : isSuitable(year, month, day) ? <Chip color="success" label="Suitable"></Chip> : <Chip color="error" label="Not Suitable"></Chip>}</td>
                                        <td style={{ textAlign: "end" }}>
                                            <Button
                                                sx={{
                                                    color: "white", padding: "10px",
                                                    border: "none", borderRadius: "10px",
                                                    backgroundColor: "#16CDB7",      // disabled durumunda metin rengi
                                                    '&.Mui-disabled': {
                                                        backgroundColor: '#ccc', // disabled durumunda arka plan rengi
                                                        color: 'white',           // disabled durumunda metin rengi
                                                    },
                                                    fontSize: "10px"
                                                }}
                                                variant='contained' disabled={isSuitable(year, month, day) == false} onClick={() => { setPageIndex(1); setSelectedDate(new Date(year, month, day)) }}>Make an Appointment</Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
    else {
        return (
            <div style={{ padding: "20px" }}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Button onClick={() => { setPageIndex(0); }} variant='contained' style={{ padding: "10px", borderRadius: "10px", backgroundColor: "#16CDB7", color: "white", border: "none" }}><i style={{ fontSize: "20px" }} class="ri-arrow-left-line"></i></Button>
                    <div style={{ display: "flex", alignItems: "end", justifyContent: "end", flexDirection: "column" }}>
                        <p style={{ fontSize: "30px" }}>{selectedDate.toLocaleDateString('tr-TR', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                        })}</p>

                    </div>
                </div>
                <div style={{ overflow: "auto" }}>
                    <table class="table" style={{ marginTop: "20px" }}>
                        <thead >
                            <tr>
                                <th scope="col" style={{ color: "#5c5c5c", fontSize: "12px", textAlign: "start", fontWeight: "normal" }}>Time</th>
                                <th scope="col" style={{ color: "#5c5c5c", fontSize: "12px", textAlign: "start", fontWeight: "normal" }}>Status</th>
                                <th scope="col" style={{ color: "#5c5c5c", fontSize: "12px", textAlign: "start", fontWeight: "normal" }}>Appointee</th>
                                <th scope="col" style={{ color: "#969696", fontSize: "14px" }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {timeSlots.map((slot, index) => {

                                var randevu = getRandevu(slot)

                                return (
                                    <tr key={index}>
                                        <td style={{ textAlign: "start", fontSize: "12px" }} key={index}>{slot}</td>
                                        <td style={{ textAlign: "start" }}>{randevu == undefined ? <Chip color="success" label="Suitable"></Chip> : randevu.appstate == 1 ? <Chip color="error" label="Full"></Chip> : <Chip color="warning" label="In Request Stage"></Chip>}</td>
                                        <td style={{ textAlign: "start", color: "#5c5c5c", fontSize: "14px" }} key={index}>{randevu != undefined ? randevu.name : "-"}</td>
                                        <td style={{ textAlign: "end", color: "#5c5c5c" }} key={index}>
                                            <Button
                                                sx={{
                                                    color: "white", padding: "10px",
                                                    border: "none", borderRadius: "10px",
                                                    fontSize: "12px",
                                                    backgroundColor: "#16CDB7",      // disabled durumunda metin rengi
                                                    '&.Mui-disabled': {
                                                        backgroundColor: '#ccc', // disabled durumunda arka plan rengi
                                                        color: 'white',        // disabled durumunda metin rengi
                                                    },
                                                    width: "100px",
                                                    marginBottom: "5px"
                                                }}
                                                variant='contained' disabled={randevu == undefined}
                                                onClick={() => {
                                                    setAddRandevu(randevu)
                                                    setIsSendMailModal(true)
                                                }}

                                            ><i class="ri-send-plane-fill"></i></Button>

                                            <Button
                                                sx={{
                                                    color: "white", padding: "10px",
                                                    border: "none", borderRadius: "10px",
                                                    backgroundColor: randevu != undefined ? randevu.appstate == 1 ? "#16CDB7" : "#eba434" : "#16CDB7",      // disabled durumunda metin rengi
                                                    '&.Mui-disabled': {
                                                        backgroundColor: '#ccc', // disabled durumunda arka plan rengi
                                                        color: 'white',           // disabled durumunda metin rengi
                                                    },
                                                    fontSize: "9px",
                                                    width: "100px",
                                                    marginBottom: "5px",
                                                    height: "45px",
                                                    marginLeft: "5px"
                                                }}
                                                disabled={new Date().setHours(0, 0, 0, 0) > selectedDate.setHours(0, 0, 0, 0) && randevu == undefined}
                                                variant='contained' onClick={() => {
                                                    if (randevu == undefined) {
                                                        setAddRandevu({
                                                            starttime: slot.replace(/\s+/g, '').split("-")[0],
                                                            endtime: slot.replace(/\s+/g, '').split("-")[1],
                                                            date: formatDate(selectedDate),
                                                            servisid: -1,
                                                            name: "",
                                                            phone: "",
                                                            mail: "",
                                                            birthdate: new Date().toLocaleDateString('tr-TR', {
                                                                day: '2-digit',
                                                                month: '2-digit',
                                                                year: 'numeric',
                                                            }),
                                                            userid: null,
                                                            state: 1
                                                        })
                                                        getServices()
                                                        setIsAddRandevuModal(true)
                                                    }
                                                    else {
                                                        setIsShowRandevuModal(true)
                                                        setRandevu(randevu)
                                                    }
                                                }}>{randevu == undefined ? "Make an Appointment" : "View"}</Button>
                                        </td>

                                    </tr>

                                )
                            }
                            )}
                        </tbody>
                    </table>
                </div>
                {
                    isAddRandevuModal &&
                    <div style={{ position: "absolute", top: "0px", left: "0px", width: "100%", height: "100%", zIndex: "1000000000000000000000", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <div style={{ backgroundColor: "black", width: "100%", height: "100%", opacity: "0.4", position: "absolute", top: "0px", left: "0px", zIndex: "1000000000000000" }} onClick={() => setIsAddRandevuModal(false)}></div>
                        <div style={{ width: "350px", height: "auto", zIndex: "1000000000000000000000", backgroundColor: "white", borderRadius: "20px", padding: "20px" }}>
                            <h2 style={{ textAlign: "start", color: "#969696", fontSize: "14px", marginBottom: "20px" }}>Create an Appointment</h2>
                            <div>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "start", marginBottom: "10px", marginTop: "20px" }}>
                                    <span style={{ marginRight: "5px" }}>Time:</span>
                                    <b>{addRandevu.starttime} - {addRandevu.endtime}</b>
                                </div>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "start" }}>
                                    <span style={{ marginRight: "5px" }}>Date:</span>
                                    <b>{addRandevu.date}</b>
                                </div>
                                <div>
                                    <FormControl style={{ width: "100%", marginTop: "20px" }}>
                                        <InputLabel id="demo-simple-select-label">Services</InputLabel>
                                        <Select
                                            style={{ width: "100%", textAlign: "center" }}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Services"
                                            value={addRandevu.servisid}
                                            onChange={(e) => setAddRandevu({ ...addRandevu, servisid: e.target.value })}
                                        >
                                            <MenuItem value={-1}>Choose Service</MenuItem>
                                            {
                                                servicesList.map((service, index) => {
                                                    return (
                                                        <MenuItem value={service.id}>{service.name}</MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                                <TextField id="outlined-basic" label="Customer Name" variant="outlined" type='text' placeholder='Customer Name' style={{ marginTop: "10px", width: "100%", height: "40px" }} value={addRandevu.name} onChange={(e) => setAddRandevu({ ...addRandevu, name: e.target.value })} />
                                <TextField id="outlined-basic" label="Customer Phone" variant="outlined" placeholder='Customer Phone' style={{ marginTop: "30px", width: "100%", height: "40px" }} value={addRandevu.phone} onChange={(e) => setAddRandevu({ ...addRandevu, phone: e.target.value })} />
                                <TextField id="outlined-basic" label="Customer E-Mail" variant="outlined" type="text" placeholder='Customer E-Mail' style={{ marginTop: "30px", width: "100%", height: "40px" }} value={addRandevu.mail} onChange={(e) => setAddRandevu({ ...addRandevu, mail: e.target.value })} />
                                <TextField id="outlined-basic" label="Customer Birthdate" variant="outlined" type="date" style={{ marginTop: "30px", width: "100%", height: "40px" }} value={addRandevu.birthdate} onChange={(e) => setAddRandevu({ ...addRandevu, birthdate: e.target.value })} />

                            </div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "end", marginTop: "40px" }}>
                                <Button variant="contained" style={{ padding: "10px", borderRadius: "10px", backgroundColor: "#16CDB7", color: "white", border: "none" }} disabled={isAppointmentAddLoading} onClick={() => saveAppointment()}>
                                    {
                                        isAppointmentAddLoading ? <div class="spinner-border text-light" role="status"></div> : "Kaydet"
                                    }
                                </Button>
                            </div>
                        </div>
                    </div>
                }
                {
                    isShowRandevuModal &&
                    <div style={{ position: "absolute", top: "0px", left: "0px", width: "100%", height: "100%", zIndex: "1000000000000000000000", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <div style={{ backgroundColor: "black", width: "100%", height: "100%", opacity: "0.4", position: "absolute", top: "0px", left: "0px", zIndex: "1000000000000000" }} onClick={() => setIsShowRandevuModal(false)}></div>
                        <div style={{ width: "350px", height: "auto", zIndex: "1000000000000000000000", backgroundColor: "white", borderRadius: "20px", padding: "20px" }}>
                            <h2 style={{ fontSize: "20px", textAlign: "left" }}>Appointment</h2>
                            <div>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "start", marginBottom: "10px", marginTop: "20px" }}>
                                    <span style={{ marginRight: "5px" }}>Time:</span>
                                    <b>{randevu.starttime} - {randevu.endtime}</b>
                                </div>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "start" }}>
                                    <span style={{ marginRight: "5px" }}>Date:</span>
                                    <b>{randevu.date.split("T")[0]}</b>
                                </div>
                                <h2 style={{ fontSize: "20px", textAlign: "left", marginTop: "20px" }}>Customer Information</h2>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "start", marginTop: "10px", fontSize: "14px" }}>
                                    <span style={{ marginRight: "5px" }}>Sefa:</span>
                                    <span>{randevu.name}</span>
                                </div>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "start", marginTop: "10px", fontSize: "14px" }}>
                                    <span style={{ marginRight: "5px" }}>Phone:</span>
                                    <span>{randevu.phone}</span>
                                </div>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "start", marginTop: "10px", fontSize: "14px" }}>
                                    <span style={{ marginRight: "5px" }}>E-Mail:</span>
                                    <span>{randevu.mail}</span>
                                </div>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "start", marginTop: "10px", fontSize: "14px" }}>
                                    <span style={{ marginRight: "5px" }}>Birthdate:</span>
                                    <span>{randevu.birthdate.split("T")[0]}</span>
                                </div>
                                <h2 style={{ fontSize: "20px", textAlign: "left", marginTop: "20px" }}>Service Information</h2>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "start", marginTop: "10px", fontSize: "14px" }}>
                                    <span style={{ marginRight: "5px" }}>Service:</span>
                                    <span>{randevu.servicename}</span>
                                </div>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "start", marginTop: "10px", fontSize: "14px" }}>
                                    <span style={{ marginRight: "5px" }}>Fee:</span>
                                    <span>{randevu.serviceprice}TL</span>
                                </div>
                            </div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "end", marginTop: "40px" }}>
                                <Button variant="contained" style={{ border: "none", borderRadius: "10px", backgroundColor: "#f06960", color: "white", padding: "10px" }} onClick={() => { deleteAppointment(randevu.appointmentid); setIsShowRandevuModal(false); setRandevu(undefined) }}>
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </div>
                }
                {
                    isSendMailModal &&
                    <div style={{ position: "absolute", top: "0px", left: "0px", width: "100%", height: "100%", zIndex: "1000000000000000000000", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <div style={{ backgroundColor: "black", width: "100%", height: "100%", opacity: "0.4", position: "absolute", top: "0px", left: "0px", zIndex: "1000000000000000" }} onClick={() => setIsSendMailModal(false)}></div>
                        <div style={{ width: "350px", height: "auto", zIndex: "1000000000000000000000", backgroundColor: "white", borderRadius: "20px", padding: "20px" }}>
                            <h2 style={{ textAlign: "start", color: "#969696", fontSize: "14px", marginBottom: "20px" }}>Send Message</h2>

                            <div>

                                <h4 style={{ fontSize: "14px", textAlign: "start" }}>Name:  <b>{addRandevu.name}</b></h4>
                                <h4 style={{ fontSize: "14px", textAlign: "start" }}>E-Mail: <b>{addRandevu.mail}</b></h4>
                                <h4 style={{ fontSize: "14px", textAlign: "start" }}>Phone: <b>{addRandevu.phone}</b></h4>
                                <FormControl fullWidth style={{ marginTop: "30px", width: "100%", height: "40px" }} >
                                    <InputLabel id="demo-simple-select-label">Message Template</InputLabel>
                                    <Select
                                        value={selectedMessage.id} onChange={(e) => {
                                            var i;
                                            for (i = 0; i < messages.length; i++) {
                                                if (e.target.value == messages[i].id)
                                                    setSelectedMessage(messages[i])
                                            }
                                        }}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Message Template"
                                    >
                                        {
                                            messages.map((message, index) => {
                                                return (
                                                    <MenuItem value={message.id}><b>{message.title}</b>:{message.message}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>

                            </div>
                            {
                                isSendMailLoading &&
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "40px" }}>
                                    <div class="spinner-border text-dark" role="status"></div>
                                </div>
                            }
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "40px" }}>
                                <Button variant="contained" style={{ width: "100%", padding: "10px", borderRadius: "10px", backgroundColor: isSendMailLoading ? "#ccc" : "#16CDB7", color: "white", border: "none" }} disabled={isSendMailLoading} onClick={() => sendMail()}>
                                    Send MaIl (1 <i style={{ color: "#F0BB40" }} class="ri-coin-fill"></i>)
                                </Button>
                            </div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "10px" }}>
                                <Button variant="contained" style={{ width: "100%", padding: "10px", borderRadius: "10px", backgroundColor: isSendMailLoading ? "#ccc" : "#16CDB7", color: "white", border: "none" }} disabled={isSendMailLoading} onClick={() => sendWp()}>
                                    Send Whatsapp Message
                                    (1 <i style={{ color: "#F0BB40" }} class="ri-coin-fill"></i>)
                                </Button>
                            </div>
                        </div>
                    </div>
                }
                {
                    isSendWpModal &&
                    <div style={{ position: "absolute", top: "0px", left: "0px", width: "100%", height: "100%", zIndex: "1000000000000000000000", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <div style={{ backgroundColor: "black", width: "100%", height: "100%", opacity: "0.4", position: "absolute", top: "0px", left: "0px", zIndex: "1000000000000000" }} onClick={() => setIsSendWpModal(false)}></div>
                        <div style={{ width: "350px", height: "auto", zIndex: "1000000000000000000000", backgroundColor: "white", borderRadius: "20px", padding: "20px" }}>
                            <h2 style={{ textAlign: "start", color: "#969696", fontSize: "14px", marginBottom: "20px" }}>Send Whatsapp Message</h2>

                            <div>

                                <h4 style={{ fontSize: "14px", textAlign: "start" }}>Name:  <b>{addRandevu.name}</b></h4>
                                <h4 style={{ fontSize: "14px", textAlign: "start" }}>E-Mail: <b>{addRandevu.mail}</b></h4>
                                <h4 style={{ fontSize: "14px", textAlign: "start" }}>Phone: <b>{addRandevu.phone}</b></h4>
                                <FormControl fullWidth style={{ marginTop: "30px", width: "100%", height: "40px" }} >
                                    <InputLabel id="demo-simple-select-label">Message Template</InputLabel>
                                    <Select
                                        value={selectedMessage.id} onChange={(e) => {
                                            var i;
                                            for (i = 0; i < messages.length; i++) {
                                                if (e.target.value == messages[i].id)
                                                    setSelectedMessage(messages[i])
                                            }
                                        }}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Message Template"
                                    >
                                        {
                                            messages.map((message, index) => {
                                                return (
                                                    <MenuItem value={message.id}><b>{message.title}</b>:{message.message}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>

                            </div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "40px" }}>
                                <Button variant="contained" style={{ padding: "10px", borderRadius: "10px", backgroundColor: "#16CDB7", color: "white", border: "none" }} disabled={isSendMailLoading} onClick={() => sendWp()}>
                                    {
                                        isSendMailLoading ? <div class="spinner-border text-light" role="status"></div> : "Send Whatsapp Message"
                                    }
                                    (1 <i style={{ color: "#F0BB40" }} class="ri-coin-fill"></i>)
                                </Button>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default Personels;

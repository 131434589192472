import { useEffect, useState } from "react"

function Logout() {

    useEffect(()=>{
        localStorage.removeItem("token")
        localStorage.removeItem("user")
        window.location.href ="/"
    },[])

  return (
    <div className="App">
        Çıkış Yapıyorsunuz lütfen bekleyiniz...
    </div>
  );
}

export default Logout;
